$media: screen;
$feature_min: min-width;
$feature_max: max-width;
$value_one: 576px;
$value_two: 767px;
$value_three: 768px;
$value_four: 991px;
$value_five: 992px;
$value_six: 1199px;
$value_seven: 1200px;
$value_eight: 1275px;
$main-font-family: 'Poppins', sans-serif; 
$optional-font-family: 'Open Sans', sans-serif;
$main-color: #92278f;
$optional-color: #0eb582;
$optional-color-hover: #0aa361;
$grey-color: #eeeeee;
$white-color: #ffffff;
$black-color: #252525;
$paragraph-color: #727695;
$font-size: 15px;
$transition: .5s;

@media only screen and ($feature_min : $value_five) {
    .slider-area {
        margin-top: 42px !important;
    }
}


.course-area {
    position: relative;
    z-index: 1;
}

.course-content {

    margin-top: 50px;

    .sub-title {
        text-transform: uppercase;
        display: block;
        color: $main-color;
        font-weight: 500;
        margin-bottom: 10px;
    }

    h2 {
        margin-bottom: 15px;
        line-height: 1.3;

        font: {
            size: 37px;
            weight: 700;
        }

        ;
    }

    h6 {
        margin-bottom: 14px;
        line-height: 1.6;
    }

    .panel {
        width: 100%;
        height: auto;
        position: relative;
        min-height: 250px;
    }
    

    .features-text {
        margin-top: 25px; 
        

        h5 {
            margin-bottom: 22px;
            position: relative;

            font: {
                size: 17px;
                weight: 600;
            }

            ;

            padding: {
                left: 47px;
                top: 7px;
            }

            ;
        }

        i {
            position: absolute;
            left: 0;
            top: 0;
            width: 35px;
            height: 35px;
            background-color: $optional-color;
            text-align: center;
            line-height: 35px;
            color: $white-color;
            font-size: 20px;
            box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .10);
            border-radius: 50%;
        }
    }

    .default-btn {
        margin-top: 30px;
    }

    .signature {
        margin-top: 25px;
    }

    &.left-content {
        max-width: 540px;
        margin-left: auto;

        padding: {
            left: 0;
            right: 15px;
        }

        ;
    }
}
 .courses-accordion {
        margin-top: 20px;

        .accordionx {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            .accordion-itemx {
                background-color: #fdfdfd;

                .accordion-title {
                    display: block;
                    border: 1px solid #eeeeee;
                    border-radius: 0;
                    position: relative;
                    padding: 13px 40px 13px 15px;
                    border-top: none;
                    color: $black-color;
                    font: {
                        size: 16px;
                        weight: 600;
                    };
                    i {
                        position: absolute;
                        right: 15px;
                        top: 50%;
                        transform: translateY(-50%);
                        font-size: 20px;
                    }
                    &.active {
                        i {
                            transform: translateY(-50%) rotate(180deg);
                        }
                    }
                    &:hover, &.active {
                        background-color: $main-color;
                        color: $white-color;
                        border-color: $main-color !important;
                    }
                }
                &:first-child {
                    .accordion-title {
                        border-top: 1px solid #eeeeee;
                    }
                }
                .accordion-content {
                    border: 1px solid #eeeeee;
                    border-top: none;
                    display: none;
                    padding: 10px 15px;

                    &.show {
                        display: block;
                    }
                    .courses-lessons {
                        padding-left: 0;
                        margin-bottom: 0;
                        list-style-type: none;

                        .single-lessons {
                            border-bottom: 1px solid #eeeeee;
                            position: relative;
                            padding: {
                                top: 15px;
                                right: 210px;
                                bottom: 15px;
                            };
                             .bx-check-circle{
                                     color:#0eb582;
                                     font-size:24px;
                                 margin-right:5px;
                                 }
                              .bx-circle{
                                     color:#ccc;
                                     font-size:24px;
                                 margin-right:5px;
                                 }
                           
                              &.active {
                        i {
                            transform: translateY(-50%) rotate(180deg);
                        }
                    }
                    &:hover, &.active {
                     
                        color: $main-color;
                       
                        .lessons-title {
                            color: $main-color;
                        }
                    }

                            &:last-child {
                                border-bottom: none;
                            }
                            .number {
                                color: $black-color;
                                display: inline-block;
                                margin-right: 3px;
                                font: {
                                    weight: 500;
                                    size: 15.5px;
                                };
                            }
                            .preview {
                                padding: 1px 7px 0px;
                                text-align: center;
                                margin-left: 5px;
                                background: #0eb582;
                                color: $white-color;
                                font: {
                                    weight: 500;
                                    size: 12px;
                                };
                            }
                            .locked {
                                padding: 1px 7px 0px;
                                text-align: center;
                                margin-left: 5px;
                                background: red;
                                color: $white-color;
                                font: {
                                    weight: 500;
                                    size: 12px;
                                };
                            }
                            .lessons-title {
                                display: inline-block;
                                color: #726b6b;
                                font: {
                                    size: 15.5px;
                                };
                                &:hover {
                                    color: $main-color;
                                }
                            }
                            .lessons-info {
                                position: absolute;
                                right: 0;
                                top: 50%;
                                transform: translateY(-50%);

                                .duration {
                                    display: inline-block;
                                    position: relative;
                                    padding-left: 20px;
                                    font-weight: 500;
                                    margin-right: 10px;
                                    color: #726b6b;

                                    i {
                                        position: absolute;
                                        left: 0;
                                        top: 3px;
                                        color: $main-color;
                                    }
                                }
                                .attrachment-video {
                                    display: inline-block;
                                    position: relative;
                                    padding-left: 21px;
                                    font-weight: 500;
                                    color: #726b6b;

                                    i {
                                        position: absolute;
                                        left: 0;
                                        font-size: 17px;
                                        top: 2px;
                                        color: $main-color;
                                    }
                                    &:hover {
                                        color: $main-color;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }