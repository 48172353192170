 .likebox{
        
        color: white !important;
        background-color: #92278f !important;
        border-color: #92278f !important;
        }
    .likebox:hover{ 
        color: #92278f !important; 
        background-color: white !important;
        border-color: #92278f !important;

        }
      .edit-account .checkbox{background-color:#fff;display:inline-block;height:20px;margin:0 .25em;width:20px;border-radius:4px;border:1px solid #ccc;float:right}
  .edit-account .checkbox span{display:block;height:28px;position:relative;width:28px;padding:0}
  .edit-account .checkbox span:after{-moz-transform:scaleX(-1) rotate(135deg);-ms-transform:scaleX(-1) rotate(135deg);-webkit-transform:scaleX(-1) rotate(135deg);transform:scaleX(-1) rotate(135deg);-moz-transform-origin:left top;
  -ms-transform-origin:left top;-webkit-transform-origin:left top;transform-origin:left top;border-right:4px solid #fff;border-top:4px solid #fff;content:'';display:block;height:18px;left:0px;position:absolute;top:11px;width:8px}
    .edit-account .checkbox span:hover:after{border-color:#999}
    .edit-account .checkbox input{display:none}
    .edit-account .checkbox input:checked + span:after{-webkit-animation:check .8s;-moz-animation:check .8s;-o-animation:check .8s;animation:check .8s;border-color:#555}
    .edit-account .checkbox input:checked + .default:after{border-color:#444}
    .edit-account .checkbox input:checked + .primary:after{border-color:#2196F3}
    .edit-account .checkbox input:checked + .success:after{border-color:#8bc34a}
    .edit-account .checkbox input:checked + .info:after{border-color:#3de0f5}
    .edit-account .checkbox input:checked + .warning:after{border-color:#FFC107}
    .edit-account .checkbox input:checked + .danger:after{border-color:#f44336}

    .input-validation-error {
    border: 1px solid #ff1949 !important;
}
.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #92278f;
}
   .discount-price{
        color: red !important;
        }
    .discount-price span{
        color: red !important;
        }
    .slider-area{
        margin-top: 160px;
    }

 .secondary_nav {
 padding:15px 0
}
.secondary_nav.is_stuck {
 z-index:99;
 width:100%!important;
 left:0
}
.secondary_nav ul li {
 margin-right:20px;
 font-weight:600;
 font-size:16px;
 font-size:1rem
}
.secondary_nav ul li a {
 color:#fff
}
.secondary_nav ul li {
    display: inline-block;
}
.secondary_nav ul li a.active,
.secondary_nav ul li a:hover {
 color:#333
}
.secondary_nav ul, a.boxed_list p {
    margin-bottom: 0;
}
.secondary_nav ol, .secondary_nav ul {
    list-style: none;
    margin: 0 0 0;
     
    padding: 0;
}
  .course-player-wrapper .course-image-wrapper .course-image-overlay,.course-player-wrapper .course-image-wrapper .course-image-overlay-next {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.5);}
    .course-image-overlay-container.purchase-button {
    text-align: center;
    margin-top: 15%;
    padding: 40px;
}
    .course-player-wrapper .play-video {
    height: 110px;
    width: 110px;
    border: 8px solid #ffa400;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    position: relative;
    display: block;
    -webkit-transition: border .2s linear 0s,color .2s linear 0s,width .2s linear 0s,background-color .2s linear 0s;
    -o-transition: border .2s linear 0s,color .2s linear 0s,width .2s linear 0s,background-color .2s linear 0s;
    transition: border .2s linear 0s,color .2s linear 0s,width .2s linear 0s,background-color .2s linear 0s;
    z-index: 5;
    margin-right: auto;
    margin-left: auto;
}
    
 .play-video-action span {
     font-size: 30px;
     color: #ffa400;
 }
    .course-player-wrapper .play-video .bx {
    font-size: 80px;
    position: absolute !important;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 5;
    left: 5px;
    height: auto;
    width: 100%;
    overflow: auto;
    color: #ffa400;
}
    .hidden {
    display: none !important;
}
    

 input.parsley-success, select.parsley-success, textarea.parsley-success {
     color: #468847;
     background-color: #DFF0D8;
     border: 1px solid #D6E9C6;
 }
 input.parsley-error, select.parsley-error, textarea.parsley-error {
     color: #B94A48;
     background-color: #F2DEDE;
     border: 1px solid #EED3D7;
 }
 .parsley-errors-list {
     margin: 2px 0 3px;
     padding: 0;
     color: #B94A48;
     list-style-type: none;
     font-size: 0.9em;
     line-height: 0.9em;
     opacity: 0;
     transition: all .3s ease-in;
     -o-transition: all .3s ease-in;
     -moz-transition: all .3s ease-in;
     -webkit-transition: all .3s ease-in;
 }
 .parsley-errors-list.filled {
     opacity: 1;
 }

 #feedback {
     z-index: 333;
     float: left;
     position: fixed;
     top: calc(50% - 47px);
     right: 0;
 }

 #feedback a {
     background: #FF4500;
     border-radius: 5px 0 0 5px;
     box-shadow: 0 0 3px rgba(0, 0, 0, .3);
     border: 3px solid #fff;
     border-right: 0;
     display: block;
     padding: 20px 12px;
     transition: all .2s ease-in-out;
 }
#feedback a img{
    height: 120px;
}
 #feedback a:hover {
     padding-right: 20px;
 }
 