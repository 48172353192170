/**
* CSS themes for simplePagination.js
* Author: Flavius Matis - http://flaviusmatis.github.com/
* URL: https://github.com/flaviusmatis/simplePagination.js
*/

ul.simple-pagination {
	list-style: none;
}

.simple-pagination {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding-left: 0;
	list-style: none;
	border-radius: .25rem;
}

.simple-pagination ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.simple-pagination li {
	list-style: none;
	padding: 0;
	margin: 0;
	float: left;
}
span.ellipse.clickable {
	cursor: pointer;
}

.ellipse input {
	width: 3em;
}

/*------------------------------------*\
	Compact Theme Styles
\*------------------------------------*/
.compact-theme span {
	color: #ff1949;
	display: inline-block;
	font-weight: 500;
	font-size: 19px;
	margin-left: 4px;
	margin-right: 4px;
	cursor:default;
}

.compact-theme a, .compact-theme span {
	color: #252525;
	display: inline-block;
	font-weight: 500;
	font-size: 19px;
	margin-left: 4px;
	margin-right: 4px;
}
.compact-theme li.disabled span{
	color:#888888 !important;
}
.compact-theme a:hover, .compact-theme li:not(.disabled):not(.active) span:hover {
	color: #ff1949;
	display: inline-block;
	font-weight: 500;
	font-size: 19px;
	margin-left: 4px;
	margin-right: 4px;
}

.compact-theme li:first-child a, .compact-theme li:first-child span {
	
}

.compact-theme li:last-child a, .compact-theme li:last-child span {
	
}

.compact-theme .current {
	position: relative;
	display: block;
	padding: .5rem .75rem;
	margin-left: -1px;
	line-height: 1.25;
	color: #ff1949;
	background-color: #fff;
	border: 1px solid #dee2e6;
}

.compact-theme .ellipse {
	background: #EAEAEA;
	padding: 0 10px;
	cursor: default;
}

