


/* open-sans-regular - latin-ext_latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('//ybo-cdn-2.ybsrv.com/fonts/open-sans-v17-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Regular'), local('OpenSans-Regular'),
    url('//ybo-cdn-2.ybsrv.com/fonts/open-sans-v17-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('//ybo-cdn-2.ybsrv.com/fonts/open-sans-v17-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */ url('//ybo-cdn-2.ybsrv.com/fonts/open-sans-v17-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */ url('//ybo-cdn-2.ybsrv.com/fonts/open-sans-v17-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('//ybo-cdn-2.ybsrv.com/fonts/open-sans-v17-latin-ext_latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
	font-display: swap;
}

/* open-sans-600 - latin-ext_latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url('//ybo-cdn-2.ybsrv.com/fonts/open-sans-v17-latin-ext_latin-600.eot'); /* IE9 Compat Modes */
    src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
    url('//ybo-cdn-2.ybsrv.com/fonts/open-sans-v17-latin-ext_latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('//ybo-cdn-2.ybsrv.com/fonts/open-sans-v17-latin-ext_latin-600.woff2') format('woff2'), /* Super Modern Browsers */ url('//ybo-cdn-2.ybsrv.com/fonts/open-sans-v17-latin-ext_latin-600.woff') format('woff'), /* Modern Browsers */ url('//ybo-cdn-2.ybsrv.com/fonts/open-sans-v17-latin-ext_latin-600.ttf') format('truetype'), /* Safari, Android, iOS */ url('//ybo-cdn-2.ybsrv.com/fonts/open-sans-v17-latin-ext_latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
	font-display: swap;
}

/* open-sans-600italic - latin-ext_latin */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 600;
    src: url('//ybo-cdn-2.ybsrv.com/fonts/open-sans-v17-latin-ext_latin-600italic.eot'); /* IE9 Compat Modes */
    src: local('Open Sans SemiBold Italic'), local('OpenSans-SemiBoldItalic'),
    url('//ybo-cdn-2.ybsrv.com/fonts/open-sans-v17-latin-ext_latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('//ybo-cdn-2.ybsrv.com/fonts/open-sans-v17-latin-ext_latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */ url('//ybo-cdn-2.ybsrv.com/fonts/open-sans-v17-latin-ext_latin-600italic.woff') format('woff'), /* Modern Browsers */ url('//ybo-cdn-2.ybsrv.com/fonts/open-sans-v17-latin-ext_latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('//ybo-cdn-2.ybsrv.com/fonts/open-sans-v17-latin-ext_latin-600italic.svg#OpenSans') format('svg'); /* Legacy iOS */
	font-display: swap;
}

/* open-sans-700 - latin-ext_latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: url('//ybo-cdn-2.ybsrv.com/fonts/open-sans-v17-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Bold'), local('OpenSans-Bold'),
    url('//ybo-cdn-2.ybsrv.com/fonts/open-sans-v17-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('//ybo-cdn-2.ybsrv.com/fonts/open-sans-v17-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */ url('//ybo-cdn-2.ybsrv.com/fonts/open-sans-v17-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */ url('//ybo-cdn-2.ybsrv.com/fonts/open-sans-v17-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */ url('//ybo-cdn-2.ybsrv.com/fonts/open-sans-v17-latin-ext_latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
	font-display: swap;
}

/* open-sans-700italic - latin-ext_latin */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 700;
    src: url('//ybo-cdn-2.ybsrv.com/fonts/open-sans-v17-latin-ext_latin-700italic.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'),
    url('//ybo-cdn-2.ybsrv.com/fonts/open-sans-v17-latin-ext_latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('//ybo-cdn-2.ybsrv.com/fonts/open-sans-v17-latin-ext_latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */ url('//ybo-cdn-2.ybsrv.com/fonts/open-sans-v17-latin-ext_latin-700italic.woff') format('woff'), /* Modern Browsers */ url('//ybo-cdn-2.ybsrv.com/fonts/open-sans-v17-latin-ext_latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('//ybo-cdn-2.ybsrv.com/fonts/open-sans-v17-latin-ext_latin-700italic.svg#OpenSans') format('svg'); /* Legacy iOS */
	font-display: swap;
}

/* open-sans-800 - latin-ext_latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    src: url('//ybo-cdn-2.ybsrv.com/fonts/open-sans-v17-latin-ext_latin-800.eot'); /* IE9 Compat Modes */
    src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'),
    url('//ybo-cdn-2.ybsrv.com/fonts/open-sans-v17-latin-ext_latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('//ybo-cdn-2.ybsrv.com/fonts/open-sans-v17-latin-ext_latin-800.woff2') format('woff2'), /* Super Modern Browsers */ url('//ybo-cdn-2.ybsrv.com/fonts/open-sans-v17-latin-ext_latin-800.woff') format('woff'), /* Modern Browsers */ url('//ybo-cdn-2.ybsrv.com/fonts/open-sans-v17-latin-ext_latin-800.ttf') format('truetype'), /* Safari, Android, iOS */ url('//ybo-cdn-2.ybsrv.com/fonts/open-sans-v17-latin-ext_latin-800.svg#OpenSans') format('svg'); /* Legacy iOS */
font-display: swap;
}

/* open-sans-800italic - latin-ext_latin */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 800;
    src: url('//ybo-cdn-2.ybsrv.com/fonts/open-sans-v17-latin-ext_latin-800italic.eot'); /* IE9 Compat Modes */
    src: local('Open Sans ExtraBold Italic'), local('OpenSans-ExtraBoldItalic'),
    url('//ybo-cdn-2.ybsrv.com/fonts/open-sans-v17-latin-ext_latin-800italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('//ybo-cdn-2.ybsrv.com/fonts/open-sans-v17-latin-ext_latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */ url('//ybo-cdn-2.ybsrv.com/fonts/open-sans-v17-latin-ext_latin-800italic.woff') format('woff'), /* Modern Browsers */ url('//ybo-cdn-2.ybsrv.com/fonts/open-sans-v17-latin-ext_latin-800italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('//ybo-cdn-2.ybsrv.com/fonts/open-sans-v17-latin-ext_latin-800italic.svg#OpenSans') format('svg'); /* Legacy iOS */
font-display: swap;
}

/* poppins-regular - latin-ext_latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Poppins Regular'), local('Poppins-Regular'),
    url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */ url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */ url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
font-display: swap;
}

/* poppins-italic - latin-ext_latin */
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    src: url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-italic.eot'); /* IE9 Compat Modes */
    src: local('Poppins Italic'), local('Poppins-Italic'),
    url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-italic.woff2') format('woff2'), /* Super Modern Browsers */ url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-italic.woff') format('woff'), /* Modern Browsers */ url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-italic.svg#Poppins') format('svg'); /* Legacy iOS */
font-display: swap;}

/* poppins-500 - latin-ext_latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    src: url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-500.eot'); /* IE9 Compat Modes */
    src: local('Poppins Medium'), local('Poppins-Medium'),
    url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-500.woff2') format('woff2'), /* Super Modern Browsers */ url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-500.woff') format('woff'), /* Modern Browsers */ url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-500.ttf') format('truetype'), /* Safari, Android, iOS */ url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-500.svg#Poppins') format('svg'); /* Legacy iOS */
font-display: swap;}

/* poppins-500italic - latin-ext_latin */
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 500;
    src: url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-500italic.eot'); /* IE9 Compat Modes */
    src: local('Poppins Medium Italic'), local('Poppins-MediumItalic'),
    url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */ url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-500italic.woff') format('woff'), /* Modern Browsers */ url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-500italic.svg#Poppins') format('svg'); /* Legacy iOS */
font-display: swap;}

/* poppins-600italic - latin-ext_latin */
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 600;
    src: url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-600italic.eot'); /* IE9 Compat Modes */
    src: local('Poppins SemiBold Italic'), local('Poppins-SemiBoldItalic'),
    url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */ url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-600italic.woff') format('woff'), /* Modern Browsers */ url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-600italic.svg#Poppins') format('svg'); /* Legacy iOS */
font-display: swap;}

/* poppins-600 - latin-ext_latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src: url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-600.eot'); /* IE9 Compat Modes */
    src: local('Poppins SemiBold'), local('Poppins-SemiBold'),
    url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-600.woff2') format('woff2'), /* Super Modern Browsers */ url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-600.woff') format('woff'), /* Modern Browsers */ url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-600.ttf') format('truetype'), /* Safari, Android, iOS */ url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-600.svg#Poppins') format('svg'); /* Legacy iOS */
font-display: swap;}

/* poppins-700 - latin-ext_latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    src: url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
    src: local('Poppins Bold'), local('Poppins-Bold'),
    url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */ url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */ url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */ url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-700.svg#Poppins') format('svg'); /* Legacy iOS */
font-display: swap;}

/* poppins-700italic - latin-ext_latin */
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 700;
    src: url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-700italic.eot'); /* IE9 Compat Modes */
    src: local('Poppins Bold Italic'), local('Poppins-BoldItalic'),
    url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */ url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-700italic.woff') format('woff'), /* Modern Browsers */ url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-700italic.svg#Poppins') format('svg'); /* Legacy iOS */
font-display: swap;}

/* poppins-800italic - latin-ext_latin */
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 800;
    src: url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-800italic.eot'); /* IE9 Compat Modes */
    src: local('Poppins ExtraBold Italic'), local('Poppins-ExtraBoldItalic'),
    url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-800italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */ url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-800italic.woff') format('woff'), /* Modern Browsers */ url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-800italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-800italic.svg#Poppins') format('svg'); /* Legacy iOS */
font-display: swap;}

/* poppins-800 - latin-ext_latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800;
    src: url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-800.eot'); /* IE9 Compat Modes */
    src: local('Poppins ExtraBold'), local('Poppins-ExtraBold'),
    url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-800.woff2') format('woff2'), /* Super Modern Browsers */ url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-800.woff') format('woff'), /* Modern Browsers */ url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-800.ttf') format('truetype'), /* Safari, Android, iOS */ url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-800.svg#Poppins') format('svg'); /* Legacy iOS */
font-display: swap;}

/* poppins-900 - latin-ext_latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    src: url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-900.eot'); /* IE9 Compat Modes */
    src: local('Poppins Black'), local('Poppins-Black'),
    url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-900.woff2') format('woff2'), /* Super Modern Browsers */ url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-900.woff') format('woff'), /* Modern Browsers */ url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-900.ttf') format('truetype'), /* Safari, Android, iOS */ url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-900.svg#Poppins') format('svg'); /* Legacy iOS */
font-display: swap;}

/* poppins-900italic - latin-ext_latin */
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 900;
    src: url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-900italic.eot'); /* IE9 Compat Modes */
    src: local('Poppins Black Italic'), local('Poppins-BlackItalic'),
    url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */ url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-900italic.woff') format('woff'), /* Modern Browsers */ url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('//ybo-cdn-2.ybsrv.com/fonts/poppins-v11-latin-ext_latin-900italic.svg#Poppins') format('svg'); /* Legacy iOS */
font-display: swap;}
