@media only screen and (min-width: 992px) {
  .slider-area {
    margin-top: 42px !important;
  }
}
.course-area {
  position: relative;
  z-index: 1;
}

.course-content {
  margin-top: 50px;
}
.course-content .sub-title {
  text-transform: uppercase;
  display: block;
  color: #92278f;
  font-weight: 500;
  margin-bottom: 10px;
}
.course-content h2 {
  margin-bottom: 15px;
  line-height: 1.3;
  font-size: 37px;
  font-weight: 700;
}
.course-content h6 {
  margin-bottom: 14px;
  line-height: 1.6;
}
.course-content .panel {
  width: 100%;
  height: auto;
  position: relative;
  min-height: 250px;
}
.course-content .features-text {
  margin-top: 25px;
}
.course-content .features-text h5 {
  margin-bottom: 22px;
  position: relative;
  font-size: 17px;
  font-weight: 600;
  padding-left: 47px;
  padding-top: 7px;
}
.course-content .features-text i {
  position: absolute;
  left: 0;
  top: 0;
  width: 35px;
  height: 35px;
  background-color: #0eb582;
  text-align: center;
  line-height: 35px;
  color: #ffffff;
  font-size: 20px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}
.course-content .default-btn {
  margin-top: 30px;
}
.course-content .signature {
  margin-top: 25px;
}
.course-content.left-content {
  max-width: 540px;
  margin-left: auto;
  padding-left: 0;
  padding-right: 15px;
}

.courses-accordion {
  margin-top: 20px;
}
.courses-accordion .accordionx {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.courses-accordion .accordionx .accordion-itemx {
  background-color: #fdfdfd;
}
.courses-accordion .accordionx .accordion-itemx .accordion-title {
  display: block;
  border: 1px solid #eeeeee;
  border-radius: 0;
  position: relative;
  padding: 13px 40px 13px 15px;
  border-top: none;
  color: #252525;
  font-size: 16px;
  font-weight: 600;
}
.courses-accordion .accordionx .accordion-itemx .accordion-title i {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
}
.courses-accordion .accordionx .accordion-itemx .accordion-title.active i {
  transform: translateY(-50%) rotate(180deg);
}
.courses-accordion .accordionx .accordion-itemx .accordion-title:hover, .courses-accordion .accordionx .accordion-itemx .accordion-title.active {
  background-color: #92278f;
  color: #ffffff;
  border-color: #92278f !important;
}
.courses-accordion .accordionx .accordion-itemx:first-child .accordion-title {
  border-top: 1px solid #eeeeee;
}
.courses-accordion .accordionx .accordion-itemx .accordion-content {
  border: 1px solid #eeeeee;
  border-top: none;
  display: none;
  padding: 10px 15px;
}
.courses-accordion .accordionx .accordion-itemx .accordion-content.show {
  display: block;
}
.courses-accordion .accordionx .accordion-itemx .accordion-content .courses-lessons {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.courses-accordion .accordionx .accordion-itemx .accordion-content .courses-lessons .single-lessons {
  border-bottom: 1px solid #eeeeee;
  position: relative;
  padding-top: 15px;
  padding-right: 210px;
  padding-bottom: 15px;
}
.courses-accordion .accordionx .accordion-itemx .accordion-content .courses-lessons .single-lessons .bx-check-circle {
  color: #0eb582;
  font-size: 24px;
  margin-right: 5px;
}
.courses-accordion .accordionx .accordion-itemx .accordion-content .courses-lessons .single-lessons .bx-circle {
  color: #ccc;
  font-size: 24px;
  margin-right: 5px;
}
.courses-accordion .accordionx .accordion-itemx .accordion-content .courses-lessons .single-lessons.active i {
  transform: translateY(-50%) rotate(180deg);
}
.courses-accordion .accordionx .accordion-itemx .accordion-content .courses-lessons .single-lessons:hover, .courses-accordion .accordionx .accordion-itemx .accordion-content .courses-lessons .single-lessons.active {
  color: #92278f;
}
.courses-accordion .accordionx .accordion-itemx .accordion-content .courses-lessons .single-lessons:hover .lessons-title, .courses-accordion .accordionx .accordion-itemx .accordion-content .courses-lessons .single-lessons.active .lessons-title {
  color: #92278f;
}
.courses-accordion .accordionx .accordion-itemx .accordion-content .courses-lessons .single-lessons:last-child {
  border-bottom: none;
}
.courses-accordion .accordionx .accordion-itemx .accordion-content .courses-lessons .single-lessons .number {
  color: #252525;
  display: inline-block;
  margin-right: 3px;
  font-weight: 500;
  font-size: 15.5px;
}
.courses-accordion .accordionx .accordion-itemx .accordion-content .courses-lessons .single-lessons .preview {
  padding: 1px 7px 0px;
  text-align: center;
  margin-left: 5px;
  background: #0eb582;
  color: #ffffff;
  font-weight: 500;
  font-size: 12px;
}
.courses-accordion .accordionx .accordion-itemx .accordion-content .courses-lessons .single-lessons .locked {
  padding: 1px 7px 0px;
  text-align: center;
  margin-left: 5px;
  background: red;
  color: #ffffff;
  font-weight: 500;
  font-size: 12px;
}
.courses-accordion .accordionx .accordion-itemx .accordion-content .courses-lessons .single-lessons .lessons-title {
  display: inline-block;
  color: #726b6b;
  font-size: 15.5px;
}
.courses-accordion .accordionx .accordion-itemx .accordion-content .courses-lessons .single-lessons .lessons-title:hover {
  color: #92278f;
}
.courses-accordion .accordionx .accordion-itemx .accordion-content .courses-lessons .single-lessons .lessons-info {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.courses-accordion .accordionx .accordion-itemx .accordion-content .courses-lessons .single-lessons .lessons-info .duration {
  display: inline-block;
  position: relative;
  padding-left: 20px;
  font-weight: 500;
  margin-right: 10px;
  color: #726b6b;
}
.courses-accordion .accordionx .accordion-itemx .accordion-content .courses-lessons .single-lessons .lessons-info .duration i {
  position: absolute;
  left: 0;
  top: 3px;
  color: #92278f;
}
.courses-accordion .accordionx .accordion-itemx .accordion-content .courses-lessons .single-lessons .lessons-info .attrachment-video {
  display: inline-block;
  position: relative;
  padding-left: 21px;
  font-weight: 500;
  color: #726b6b;
}
.courses-accordion .accordionx .accordion-itemx .accordion-content .courses-lessons .single-lessons .lessons-info .attrachment-video i {
  position: absolute;
  left: 0;
  font-size: 17px;
  top: 2px;
  color: #92278f;
}
.courses-accordion .accordionx .accordion-itemx .accordion-content .courses-lessons .single-lessons .lessons-info .attrachment-video:hover {
  color: #92278f;
}

/*# sourceMappingURL=customResponsive.css.map */
