@media only screen and (max-width: 767px) {
  body {
    font-size: 14px;
  }

  .ptb-100 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .pt-100 {
    padding-top: 60px;
  }

  .pb-100 {
    padding-bottom: 60px;
  }

  .ptb-70 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .pt-70 {
    padding-top: 30px;
  }

  .pb-70 {
    padding-bottom: 30px;
  }

  p {
    font-size: 14px;
  }

  .section-title {
    margin-bottom: 35px;
  }
  .section-title .sub-title {
    margin-bottom: 8px;
    font-size: 13.5px;
  }
  .section-title h2 {
    font-size: 27px;
  }
  .section-title p {
    max-width: 100%;
  }
  .section-title.text-left {
    text-align: center !important;
  }
  .section-title.text-left h2 {
    max-width: 100%;
  }
  .section-title.text-left .default-btn {
    display: none;
  }

  .default-btn {
    padding: 12px 20px 11px 50px;
    font-size: 13px;
  }
  .default-btn .icon-arrow {
    top: 10px;
    font-size: 22px;
  }

  .top-header {
    text-align: center;
    padding-top: 15px;
    padding-bottom: 7px;
  }

  .top-header-contact-info {
    display: flex;
    flex-wrap: wrap;
  }
  .top-header-contact-info li {
    font-size: 12.5px;
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 8px;
    margin-right: 0;
    padding-left: 0;
  }
  .top-header-contact-info li i {
    font-size: 17px;
    position: relative;
    top: 3px;
    transform: unset;
  }
  .top-header-contact-info li span {
    display: none;
  }
  .top-header-contact-info li a {
    font-size: 13px;
  }
  .top-header-contact-info li:last-child {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .top-header-btn {
    display: none;
  }

  .top-header-logo {
    display: none;
  }

  .top-header-style-two {
    text-align: center;
    border-bottom: 1px solid #eeeeee;
    padding-top: 15px;
    padding-bottom: 7px;
  }
  .top-header-style-two .top-header-contact-info {
    text-align: center;
  }
  .top-header-style-two .top-header-contact-info li {
    text-align: center;
  }

  .top-header-style-three {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .top-header-style-three .top-header-contact-info {
    flex-wrap: unset;
    padding-top: 0;
    padding-bottom: 0;
  }
  .top-header-style-three .top-header-contact-info li {
    font-size: 13px;
  }
  .top-header-style-three .top-header-contact-info li a {
    font-size: 13px;
  }
  .top-header-style-three .top-header-contact-info li:last-child {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .top-header-social {
    display: inline-block;
    margin-left: 10px;
    border-left: none;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
  }
  .top-header-social span {
    margin-right: 2px;
    font-size: 13px;
  }
  .top-header-social a {
    font-size: 15px;
  }

  .top-header-login-register {
    text-align: center;
  }

  .top-header-style-four {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .top-header-style-four .top-header-contact-info {
    padding-top: 0;
    padding-bottom: 0;
  }
  .top-header-style-four .top-header-contact-info li:last-child {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .top-header-style-four .top-header-social {
    border-left: none;
  }

  .main-banner {
    height: 100%;
    padding-top: 250px;
    padding-bottom: 125px;
  }

  .main-banner-content {
    max-width: 100%;
    margin-top: 0;
    text-align: center;
  }
  .main-banner-content .sub-title {
    padding-left: 0;
    margin-bottom: 13px;
    font-weight: 500;
    font-size: 13px;
  }
  .main-banner-content .sub-title::before {
    display: none;
  }
  .main-banner-content h1 {
    font-size: 30px;
    margin-bottom: 17px;
  }
  .main-banner-content p {
    max-width: 100%;
    line-height: 1.7;
    font-size: 15px;
  }
  .main-banner-content .btn-box {
    margin-top: 30px;
  }
  .main-banner-content .btn-box .default-btn {
    margin-right: 6px;
  }
  .main-banner-content .btn-box .optional-btn {
    margin-left: 6px;
    font-size: 13px;
  }

  .home-slides.owl-theme .owl-nav [class*=owl-] {
    left: 10px;
    font-size: 27px;
    width: 35px;
    height: 35px;
  }
  .home-slides.owl-theme .owl-nav [class*=owl-] i {
    top: 55%;
    transform: translateY(-55%);
  }
  .home-slides.owl-theme .owl-nav [class*=owl-].owl-next {
    left: auto;
    right: 10px;
  }

  .banner-section {
    height: 100%;
    padding-top: 250px;
    padding-bottom: 175px;
  }

  .banner-content {
    max-width: 100%;
    margin-top: 0;
    text-align: center;
  }
  .banner-content .sub-title {
    padding-left: 0;
    margin-bottom: 13px;
    font-weight: 500;
    font-size: 13px;
  }
  .banner-content .sub-title::before {
    display: none;
  }
  .banner-content h1 {
    font-size: 30px;
    margin-bottom: 17px;
  }
  .banner-content p {
    max-width: 100%;
    line-height: 1.7;
    font-weight: normal;
    font-size: 15px;
  }
  .banner-content .btn-box {
    margin-top: 25px;
  }
  .banner-content .btn-box .default-btn {
    margin-right: 6px;
  }
  .banner-content .btn-box .video-btn {
    margin-left: 6px;
    top: 0;
    font-size: 13px;
    padding-left: 35px;
  }
  .banner-content .btn-box .video-btn span {
    top: -6px;
    font-size: 30px;
  }
  .banner-content .btn-box .video-btn span::after, .banner-content .btn-box .video-btn span::before {
    width: 30px;
    height: 30px;
  }
  .banner-content::before {
    display: none;
  }

  .hero-banner {
    height: 100%;
    padding-top: 125px;
    padding-bottom: 80px;
  }

  .hero-banner-content {
    max-width: 100%;
    margin-left: 0;
    text-align: center;
  }
  .hero-banner-content .sub-title {
    padding-left: 0;
    margin-bottom: 13px;
    font-weight: 500;
    font-size: 13px;
  }
  .hero-banner-content .sub-title::before {
    display: none;
  }
  .hero-banner-content h1 {
    font-size: 30px;
    margin-bottom: 17px;
  }
  .hero-banner-content p {
    max-width: 100%;
    line-height: 1.7;
    font-size: 15px;
  }
  .hero-banner-content .btn-box {
    margin-top: 30px;
  }
  .hero-banner-content .btn-box .default-btn {
    margin-right: 6px;
  }
  .hero-banner-content .btn-box .optional-btn {
    margin-left: 6px;
    font-size: 13px;
  }

  .hero-banner-image {
    padding-left: 0;
    margin-top: 30px;
  }

  .divider {
    bottom: -1px;
  }

  .main-banner-wrapper {
    padding-top: 75px;
  }

  .banner-wrapper-content h1 {
    margin-bottom: 18px;
    font-size: 30px;
  }
  .banner-wrapper-content p {
    font-size: 15px;
    max-width: 100%;
  }
  .banner-wrapper-content form button {
    position: relative;
    right: 0;
    top: 0;
    height: 45px;
    font-size: 15px;
    margin-top: 15px;
    box-shadow: 0 5px 28.5px 1.5px rgba(255, 25, 73, 0.2);
  }
  .banner-wrapper-content form button:hover, .banner-wrapper-content form button:focus {
    box-shadow: 0 5px 28.5px 1.5px rgba(14, 181, 130, 0.2);
  }

  .banner-wrapper-image {
    margin-top: 35px;
  }

  .banner-wrapper {
    padding-top: 260px;
    padding-bottom: 110px;
  }

  .wrapper-content {
    padding-right: 0;
    text-align: center;
    margin-bottom: 30px;
  }
  .wrapper-content h1 {
    font-size: 33px;
    margin-bottom: 15px;
  }
  .wrapper-content form {
    margin-right: 0;
    margin-top: 25px;
  }
  .wrapper-content form button {
    position: relative;
    right: 0;
    top: 0;
    height: 50px;
    font-size: 15px;
    margin-top: 15px;
    box-shadow: 0 5px 28.5px 1.5px rgba(255, 25, 73, 0.2);
  }
  .wrapper-content form button:hover, .wrapper-content form button:focus {
    box-shadow: 0 5px 28.5px 1.5px rgba(14, 181, 130, 0.2);
  }

  .banner-courses-category ul li {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .banner-courses-category ul li a {
    font-size: 15px;
  }
  .banner-courses-category ul li a i {
    font-size: 35px;
  }

  .slider_area .tp-caption.NotGeneric-Icon, .slider_area .tp-caption .NotGeneric-Icon {
    font-size: 14px !important;
  }
  .slider_area .tp-caption.NotGeneric-Title, .slider_area .tp-caption .NotGeneric-Title {
    font-size: 33px !important;
  }
  .slider_area .tp-caption.NotGeneric-SubTitle, .slider_area .tp-caption .NotGeneric-SubTitle {
    font-size: 14px !important;
  }
  .slider_area .tp-caption.NotGeneric-btn .default-btn, .slider_area .tp-caption .NotGeneric-btn .default-btn {
    padding: 12px 20px 11px 50px !important;
    font-size: 13px !important;
  }

  .about-image {
    text-align: center;
    margin-bottom: 30px;
    padding-right: 0;
    padding-bottom: 0;
  }
  .about-image img {
    width: 100%;
  }
  .about-image img:nth-child(2) {
    position: relative;
    right: 0;
    margin-top: 30px;
  }

  .about-content {
    padding-left: 0;
    text-align: center;
  }
  .about-content .sub-title {
    margin-bottom: 8px;
    font-size: 13.5px;
  }
  .about-content h2 {
    font-size: 26px;
  }
  .about-content h6 {
    margin-bottom: 14px;
    line-height: 1.5;
  }
  .about-content .features-text {
    margin-top: 20px;
    text-align: left;
  }
  .about-content .features-text h5 {
    margin-bottom: 15px;
    max-width: 295px;
    font-size: 16px;
    padding-top: 2px;
  }
  .about-content .default-btn {
    margin-top: 25px;
  }
  .about-content.left-content {
    max-width: 100%;
    padding-right: 0;
  }

  .about-right-image {
    margin-top: 30px;
    text-align: center;
    padding-left: 0;
    padding-bottom: 0;
  }
  .about-right-image img {
    width: 100%;
  }
  .about-right-image img:nth-child(2) {
    position: relative;
    margin-top: 30px;
  }
  .about-right-image .text-box {
    left: auto;
    top: 30%;
    width: 140px;
    height: 140px;
    transform: translateY(-30%);
    right: 0;
  }
  .about-right-image .text-box .inner {
    font-size: 14px;
  }
  .about-right-image .text-box .inner span {
    font-size: 28px;
  }

  .about-inner-area {
    margin-top: 0;
  }

  .about-text {
    margin-top: 30px;
  }
  .about-text h3 {
    font-size: 18px;
  }

  .single-categories-courses-box {
    padding: 25px 20px;
  }
  .single-categories-courses-box h3 {
    font-size: 18px;
  }
  .single-categories-courses-box span {
    font-size: 13px;
  }
  .single-categories-courses-box .icon {
    width: 80px;
    height: 80px;
    font-size: 50px;
  }

  .single-categories-courses-item {
    padding: 25px 20px;
  }
  .single-categories-courses-item h3 {
    font-size: 18px;
  }
  .single-categories-courses-item span {
    font-size: 13px;
  }
  .single-categories-courses-item .icon {
    width: 80px;
    height: 80px;
    font-size: 50px;
  }
  .single-categories-courses-item:hover .icon, .single-categories-courses-item:focus .icon {
    transform: translateY(-15px);
  }
  .single-categories-courses-item:hover h3, .single-categories-courses-item:focus h3 {
    transform: translateY(-25px);
  }
  .single-categories-courses-item:hover span, .single-categories-courses-item:focus span {
    transform: translateY(-21px);
  }
  .single-categories-courses-item:hover .learn-more-btn, .single-categories-courses-item:focus .learn-more-btn {
    bottom: 15px;
  }

  .courses-categories-slides.owl-theme .owl-nav {
    margin-bottom: 30px;
  }
  .courses-categories-slides.owl-theme .owl-nav [class*=owl-] {
    font-size: 22px;
    width: 40px;
    height: 40px;
    line-height: 42px;
    position: relative;
    left: 0 !important;
    top: 0;
    transform: unset;
    opacity: 1;
    visibility: visible;
    margin-left: 5px;
    margin-right: 5px;
  }
  .courses-categories-slides.owl-theme .owl-nav [class*=owl-].owl-next {
    left: auto !important;
    right: 0 !important;
  }

  .single-courses-category a {
    padding: 20px;
    font-size: 18px;
  }
  .single-courses-category a i {
    font-size: 45px;
  }

  .single-boxes-item {
    padding: 30px;
    min-height: auto;
  }
  .single-boxes-item h3 {
    font-size: 20px;
  }
  .single-boxes-item .boxes-btn {
    font-size: 13px;
  }

  .story-content {
    margin-top: 0;
    text-align: center;
  }
  .story-content h3 {
    margin-bottom: 10px;
    font-size: 18px;
  }

  .values-content {
    margin-top: 0;
    text-align: center;
  }
  .values-content h3 {
    margin-bottom: 10px;
    font-size: 18px;
  }

  .funfacts-inner {
    border-radius: 10px;
    padding: 25px 20px 0;
  }
  .funfacts-inner .col-lg-3 {
    border-right: none;
  }

  .single-funfact {
    margin-bottom: 25px;
  }
  .single-funfact .icon {
    font-size: 35px;
    margin-top: -3px;
    margin-bottom: 8px;
  }
  .single-funfact h3 {
    font-size: 32px;
  }
  .single-funfact p {
    font-size: 14px;
  }

  .shorting-menu {
    text-align: center;
    margin-bottom: 25px;
    margin-top: 0;
  }
  .shorting-menu .filter {
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 10px;
  }

  .single-courses-box .courses-image .courses-tag {
    right: 20px;
    font-size: 13px;
  }
  .single-courses-box .courses-content {
    padding: 20px;
  }
  .single-courses-box .courses-content h3 {
    font-size: 18px;
  }
  .single-courses-box .courses-box-footer {
    padding: 20px;
    text-align: center;
  }
  .single-courses-box .courses-box-footer ul li i {
    top: 0;
    display: block;
    margin-bottom: 5px;
    font-size: 16px;
  }
  .single-courses-box .courses-box-footer ul .courses-price {
    font-size: 18px;
  }

  .single-courses-item .courses-content h3 {
    font-size: 18px;
  }
  .single-courses-item .courses-content .courses-rating .rating-total {
    display: none;
  }
  .single-courses-item .courses-box-footer {
    text-align: center;
  }
  .single-courses-item .courses-box-footer ul li i {
    top: 0;
    display: block;
    margin-bottom: 5px;
    font-size: 16px;
  }
  .single-courses-item .courses-box-footer ul .courses-price {
    font-size: 18px;
  }

  .courses-slides.owl-theme .owl-nav [class*=owl-] {
    font-size: 22px;
    width: 40px;
    height: 40px;
    line-height: 42px;
    position: relative;
    left: 0 !important;
    top: 0;
    transform: unset;
    opacity: 1;
    visibility: visible;
    margin-left: 5px;
    margin-right: 5px;
  }
  .courses-slides.owl-theme .owl-nav [class*=owl-].owl-next {
    left: auto !important;
    right: 0 !important;
  }
  .courses-slides.owl-theme .owl-dots {
    display: none !important;
  }

  .single-courses-list-box .box-item .courses-image {
    flex: 0 0 100%;
    max-width: 100%;
    border-radius: 5px 5px 0 0;
  }
  .single-courses-list-box .box-item .courses-image .image {
    border-radius: 5px 5px 0 0;
    background-image: unset !important;
  }
  .single-courses-list-box .box-item .courses-image .image img {
    display: inline-block;
    border-radius: 5px 5px 0 0;
  }
  .single-courses-list-box .box-item .courses-desc {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .single-courses-list-box .box-item .courses-desc .courses-content h3 {
    font-size: 18px;
  }
  .single-courses-list-box .box-item .courses-desc .courses-box-footer {
    text-align: center;
  }
  .single-courses-list-box .box-item .courses-desc .courses-box-footer ul li i {
    top: 0;
    display: block;
    margin-bottom: 5px;
    font-size: 16px;
  }
  .single-courses-list-box .box-item .courses-desc .courses-box-footer ul .courses-price {
    font-size: 18px;
  }

  .courses-list-tab .tabs {
    margin-bottom: 35px;
    padding-left: 0;
    padding-right: 0;
  }
  .courses-list-tab .tabs li {
    display: block;
    margin-left: 0;
    margin-right: 0;
  }
  .courses-list-tab .tabs li a {
    display: block;
    font-size: 15px;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .courses-topbar {
    text-align: center;
  }
  .courses-topbar .topbar-result-count p {
    font-size: 15px;
  }
  .courses-topbar .topbar-ordering {
    margin-top: 5px;
    margin-bottom: 7px;
  }

  .courses-details-header .courses-title h2 {
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 1.4;
  }
  .courses-details-header .courses-meta {
    margin-top: 5px;
  }
  .courses-details-header .courses-meta ul li {
    font-size: 14px;
    margin-top: 15px;
    margin-right: 15px;
    padding-right: 15px;
    padding-left: 0;
  }
  .courses-details-header .courses-meta ul li i.def {
    display: none;
  }
  .courses-details-header .courses-meta ul li span {
    font-size: 13px;
  }
  .courses-details-header .courses-price {
    text-align: left;
    margin-top: 20px;
  }
  .courses-details-header .courses-price .courses-review {
    display: block;
    margin-bottom: 10px;
  }
  .courses-details-header .courses-price .price {
    font-size: 30px;
  }

  .courses-details-desc h3 {
    font-size: 18px;
  }
  .courses-details-desc .why-you-learn ul li {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .courses-details-desc .why-you-learn ul li span i {
    top: 0;
  }
  .courses-details-desc .courses-author .author-profile {
    padding: 0 20px 20px;
  }
  .courses-details-desc .courses-author .author-profile .author-profile-title .author-profile-title-details .author-profile-details h4 {
    font-size: 15px;
  }
  .courses-details-desc .courses-author .author-profile .author-profile-title .author-profile-title-details .author-profile-details span {
    font-size: 13px;
  }
  .courses-details-desc .courses-author .author-profile .author-profile-title .author-profile-title-details a {
    padding: 5px 10px 4px;
    font-size: 13px;
  }

  .courses-review-comments .user-review {
    padding: 20px 0 20px 0;
  }
  .courses-review-comments .user-review img {
    position: relative;
    left: 0;
    top: 0;
    margin-bottom: 15px;
  }
  .courses-review-comments .user-review .review-rating .review-stars i {
    font-size: 15px;
  }
  .courses-review-comments .user-review .review-rating span {
    top: 0;
  }

  .courses-sidebar-information ul li {
    padding: 12px 10px;
    font-size: 14px;
  }

  .courses-sidebar-syllabus {
    margin-bottom: 30px;
  }
  .courses-sidebar-syllabus h3 {
    margin-bottom: 25px;
    font-size: 18px;
  }

  .mission-content {
    padding: 25px;
    max-width: 100%;
  }
  .mission-content .section-title {
    margin-bottom: 25px;
  }
  .mission-content .section-title.text-left {
    text-align: left !important;
  }
  .mission-content h3 {
    margin-bottom: 13px;
    padding-bottom: 13px;
    font-size: 18px;
    line-height: 1.6;
  }
  .mission-content .owl-theme .owl-nav [class*=owl-] {
    font-size: 25px;
    width: 35px;
    height: 35px;
    line-height: 41px;
    left: -35px;
  }
  .mission-content .owl-theme .owl-nav [class*=owl-].owl-next {
    left: auto;
    right: -35px;
  }

  .team-area {
    overflow: hidden;
  }

  .single-instructor-member .member-content h3 {
    font-size: 18px;
  }
  .single-instructor-member .member-content span {
    font-size: 13.5px;
  }

  .team-btn-box {
    margin-top: 20px;
  }

  .single-instructor-box .content {
    margin-top: 20px;
  }
  .single-instructor-box .content h3 {
    font-size: 18px;
  }
  .single-instructor-box .content span {
    font-size: 13.5px;
  }

  .team-slides.owl-theme .owl-nav {
    margin-bottom: 30px;
  }
  .team-slides.owl-theme .owl-nav [class*=owl-] {
    font-size: 22px;
    width: 40px;
    height: 40px;
    line-height: 42px;
    position: relative;
    left: 0 !important;
    top: 0;
    transform: unset;
    opacity: 1;
    visibility: visible;
    margin-left: 5px;
    margin-right: 5px;
  }
  .team-slides.owl-theme .owl-nav [class*=owl-].owl-next {
    left: auto !important;
    right: 0 !important;
  }

  .single-team-member .member-content {
    top: auto;
    padding: 20px;
    bottom: 0;
  }
  .single-team-member .member-content h3 {
    font-size: 18px;
  }
  .single-team-member .member-content span {
    font-size: 13.5px;
  }

  .instructor-details-area .section-title {
    margin-bottom: 30px;
  }
  .instructor-details-area .section-title h2 {
    font-size: 21px;
  }

  .instructor-details-desc {
    margin-bottom: 40px;
  }
  .instructor-details-desc .instructor-details-sidebar {
    margin-bottom: 30px;
  }
  .instructor-details-desc .instructor-details {
    text-align: center;
  }
  .instructor-details-desc .instructor-details h3 {
    margin-bottom: 8px;
    font-size: 20px;
  }
  .instructor-details-desc .instructor-details .sub-title {
    font-size: 14px;
  }
  .instructor-details-desc .instructor-details .instructor-details-info .row .col-lg-6:nth-child(2) {
    margin-top: 25px;
  }
  .instructor-details-desc .instructor-details .instructor-details-info h3 {
    font-size: 18px;
  }
  .instructor-details-desc .instructor-details .instructor-details-info ul li {
    font-size: 14px;
  }
  .instructor-details-desc .instructor-details .instructor-details-info ul li span {
    font-size: 13px;
  }

  .offer-area .section-title {
    margin-bottom: 5px;
  }

  .single-offer-box {
    padding: 25px 20px;
    margin-top: 30px;
  }
  .single-offer-box .icon {
    width: 80px;
    height: 80px;
    font-size: 40px;
    margin-bottom: 25px;
  }
  .single-offer-box h3 {
    font-size: 18px;
  }

  .single-testimonials-item {
    padding: 25px 20px 90px;
  }
  .single-testimonials-item p {
    font-size: 14.5px;
  }
  .single-testimonials-item .info h3 {
    font-size: 18px;
  }

  .testimonials-slides.owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 0;
  }

  .single-feedback-item {
    text-align: center;
    max-width: 100%;
  }
  .single-feedback-item p {
    font-size: 16px;
  }
  .single-feedback-item .info {
    max-width: 260px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    padding-right: 100px;
    padding-bottom: 14px;
  }
  .single-feedback-item .info h3 {
    font-size: 16px;
  }
  .single-feedback-item .info img {
    width: 75px !important;
    height: 75px;
    right: 10px;
  }
  .single-feedback-item .info::before {
    display: none;
  }

  .feedback-slides.owl-theme {
    margin-bottom: 0;
  }
  .feedback-slides.owl-theme .single-feedback-item {
    margin-bottom: 30px;
  }
  .feedback-slides.owl-theme .owl-nav [class*=owl-] {
    font-size: 22px;
    width: 40px;
    height: 40px;
    line-height: 42px;
    position: relative;
    left: 0 !important;
    top: 0;
    transform: unset;
    opacity: 1;
    visibility: visible;
    margin-left: 5px;
    margin-right: 5px;
  }
  .feedback-slides.owl-theme .owl-nav [class*=owl-].owl-next {
    left: auto !important;
    right: 0 !important;
  }

  .single-features-box {
    min-height: 360px;
    padding: 25px;
    border-right: none;
    border-bottom: 1px solid #cccccc;
  }
  .single-features-box .inner-content {
    right: 25px;
    bottom: 25px;
    left: 25px;
  }
  .single-features-box .inner-content h3 {
    margin-bottom: 12px;
    font-size: 18px;
  }
  .single-features-box:hover .inner-content h3 {
    transform: translateY(-67px);
  }
  .single-features-box:hover .inner-content p {
    transform: translateY(-67px);
  }

  .col-lg-3:nth-child(4) .single-features-box {
    border-bottom: none;
  }

  .single-work-process .icon {
    width: 70px;
    height: 70px;
    margin-bottom: 20px;
    font-size: 35px;
  }
  .single-work-process h3 {
    font-size: 18px;
  }
  .single-work-process::before {
    display: none;
  }

  .students-feedback-form-content {
    text-align: center;
    margin-bottom: 35px;
  }
  .students-feedback-form-content h2 {
    font-size: 30px;
  }

  .students-feedback-form h3 {
    padding: 20px 15px;
    font-size: 17px;
  }
  .students-feedback-form::after {
    display: none;
  }
  .students-feedback-form form {
    padding: 25px;
  }

  .feedback-form-area {
    margin-top: -10px;
  }

  .feedback-form-image {
    display: none;
  }

  .feedback-form-inner .feedback-form h3 {
    font-size: 18px;
  }
  .feedback-form-inner .feedback-form form {
    padding: 20px;
  }

  .single-blog-post .post-content .post-meta {
    margin-bottom: 12px;
  }
  .single-blog-post .post-content .post-meta li.post-author {
    top: -2px;
  }
  .single-blog-post .post-content .post-meta li.post-author img {
    top: -1px;
  }
  .single-blog-post .post-content .post-meta li.post-author a {
    top: 2px;
    position: relative;
  }
  .single-blog-post .post-content .post-meta li::before {
    top: 8px;
  }
  .single-blog-post .post-content h3 {
    margin-bottom: 12px;
    font-size: 18px;
  }
  .single-blog-post .post-content .read-more-btn {
    font-size: 14px;
  }

  .blog-slides.owl-theme .owl-nav {
    margin-bottom: 30px;
  }
  .blog-slides.owl-theme .owl-nav [class*=owl-] {
    font-size: 22px;
    width: 40px;
    height: 40px;
    line-height: 42px;
    position: relative;
    left: 0 !important;
    top: 0;
    transform: unset;
    opacity: 1;
    visibility: visible;
    margin-left: 5px;
    margin-right: 5px;
  }
  .blog-slides.owl-theme .owl-nav [class*=owl-].owl-next {
    left: auto !important;
    right: 0 !important;
  }

  .blog-details-desc .article-content .entry-meta {
    margin-bottom: -15px;
  }
  .blog-details-desc .article-content .entry-meta ul li {
    font-size: 14px;
    margin-bottom: 15px;
    margin-right: 15px;
    padding-right: 15px;
    padding-left: 0;
  }
  .blog-details-desc .article-content .entry-meta ul li i {
    display: none;
  }
  .blog-details-desc .article-content .entry-meta ul li span {
    font-size: 13px;
  }
  .blog-details-desc .article-content h3 {
    font-size: 19px;
    margin-bottom: 13px;
    line-height: 1.5;
  }
  .blog-details-desc .article-footer {
    text-align: center;
  }
  .blog-details-desc .article-footer .article-tags {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .blog-details-desc .article-footer .article-share {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .blog-details-desc .article-footer .article-share .social {
    text-align: center;
    margin-top: 15px;
  }
  .blog-details-desc .article-author .author-profile {
    padding: 0 20px 20px;
  }
  .blog-details-desc .article-author .author-profile .author-profile-title .author-profile-title-details .author-profile-details h4 {
    font-size: 15px;
  }
  .blog-details-desc .article-author .author-profile .author-profile-title .author-profile-title-details .author-profile-details span {
    font-size: 13px;
  }
  .blog-details-desc .article-author .author-profile .author-profile-title .author-profile-title-details a {
    padding: 5px 10px 4px;
    font-size: 13px;
  }

  .comments-area .comments-title {
    font-size: 19px;
  }
  .comments-area .comment-author .avatar {
    height: 50px;
    left: 0;
    position: relative;
    width: 50px;
    display: block;
    margin-bottom: 10px;
  }
  .comments-area .comment-body {
    padding-left: 0;
  }
  .comments-area .comment-respond .comment-reply-title {
    font-size: 19px;
  }
  .comments-area .comment-respond .comment-form-author {
    width: 100%;
    padding-right: 0;
  }
  .comments-area .comment-respond .comment-form-email {
    width: 100%;
    padding-left: 0;
  }
  .comments-area .comment-respond .form-submit input {
    font-size: 15px;
  }

  .single-events-box .events-box .events-image {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .single-events-box .events-box .events-image .image {
    border-radius: 5px 5px 0 0;
    background-image: unset !important;
  }
  .single-events-box .events-box .events-image .image img {
    display: inline-block;
    border-radius: 5px 5px 0 0;
  }
  .single-events-box .events-box .events-content {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .single-events-box .events-box .events-content .content {
    padding: 25px;
  }
  .single-events-box .events-box .events-content .content h3 {
    font-size: 18px;
  }
  .single-events-box .events-box .events-date {
    flex: 0 0 100%;
    max-width: 100%;
    border-radius: 0 0 5px 5px;
  }
  .single-events-box .events-box .events-date .date {
    border-radius: 0 0 5px 5px;
    font-size: 20px;
    padding: 20px 10px;
  }
  .single-events-box .events-box .events-date .date h3 {
    font-size: 20px;
  }
  .single-events-box .events-box .events-date .date p {
    font-size: 15px;
  }
  .single-events-box .events-box .events-date .date i {
    font-size: 100px;
  }

  .events-details-header .back-all-events {
    font-size: 13px;
    margin-bottom: 12px;
  }
  .events-details-header .back-all-events i {
    top: -1px;
  }
  .events-details-header h3 {
    font-size: 20px;
    margin-bottom: 0;
  }
  .events-details-header .events-meta {
    margin-top: 0;
  }
  .events-details-header .events-meta ul li {
    font-size: 14px;
    margin-top: 15px;
    margin-right: 15px;
    padding-right: 15px;
    padding-left: 0;
  }
  .events-details-header .events-meta ul li span {
    font-size: 13px;
  }
  .events-details-header .events-meta ul li i {
    display: none;
  }

  .events-details .events-info-links a {
    padding: 5px 10px 6px;
    font-size: 14px;
  }

  .countdown-content {
    margin-bottom: 40px;
    text-align: center;
  }
  .countdown-content h2 {
    font-size: 26px;
    margin-bottom: 15px;
    line-height: 1.4;
  }
  .countdown-content .sign-up-btn {
    font-size: 14px;
  }

  .countdown-timer #timer div {
    font-size: 33px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .countdown-timer #timer div span {
    font-size: 14px;
    margin-top: -5px;
  }
  .countdown-timer #timer div::before {
    display: none;
  }

  .become-instructor-partner-area .col-lg-6:nth-child(1) {
    order: 2;
  }
  .become-instructor-partner-area .col-lg-6:nth-child(2) {
    order: 1;
  }
  .become-instructor-partner-area .col-lg-6:nth-child(3) {
    order: 3;
  }
  .become-instructor-partner-area .col-lg-6:nth-child(4) {
    order: 4;
  }

  .become-instructor-partner-content {
    padding: 30px;
    text-align: center;
  }
  .become-instructor-partner-content h2 {
    margin-bottom: 10px;
    font-size: 27px;
  }
  .become-instructor-partner-content p {
    margin-bottom: 20px;
    max-width: 100%;
  }

  .become-instructor-partner-image {
    background-image: unset !important;
  }
  .become-instructor-partner-image img {
    display: inline-block;
  }

  .page-title-area {
    padding-top: 265px;
    padding-bottom: 90px;
  }
  .page-title-area.page-title-style-two {
    padding-top: 270px;
    padding-bottom: 100px;
  }
  .page-title-area.page-title-style-three {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .page-title-content h2 {
    font-size: 30px;
  }

  blockquote, .blockquote {
    padding: 20px !important;
  }
  blockquote p, .blockquote p {
    font-size: 17px !important;
  }

  .prev-link-wrapper {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0;
  }
  .prev-link-wrapper .prev-title {
    font-size: 15px;
  }
  .prev-link-wrapper .meta-wrapper {
    font-size: 13px;
  }

  .next-link-wrapper {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
    margin-top: 30px;
  }
  .next-link-wrapper .next-title {
    font-size: 15px;
  }
  .next-link-wrapper .meta-wrapper {
    font-size: 13px;
  }

  .pricing-table table thead tr th {
    padding: 12px 15px 10px;
    font-size: 16px;
    white-space: nowrap;
  }
  .pricing-table table thead tr th:nth-child(1) {
    padding-left: 20px;
  }
  .pricing-table table tbody td {
    padding: 14px 15px;
    font-size: 14px;
    white-space: nowrap;
  }
  .pricing-table table tbody td:nth-child(1) {
    padding-left: 20px;
  }

  .login-image {
    background-image: unset !important;
  }
  .login-image img {
    display: inline-block;
  }

  .login-content {
    height: 100%;
    padding: 60px 15px;
  }
  .login-content .login-form .logo {
    margin-bottom: 25px;
  }
  .login-content .login-form h3 {
    font-size: 26px;
  }

  .register-image {
    background-image: unset !important;
  }
  .register-image img {
    display: inline-block;
  }

  .register-content {
    height: 100%;
    padding: 60px 15px;
  }
  .register-content .register-form .logo {
    margin-bottom: 25px;
  }
  .register-content .register-form h3 {
    font-size: 26px;
  }

  .widget-area {
    margin-top: 40px;
  }
  .widget-area .widget {
    margin-bottom: 30px;
  }
  .widget-area .widget:last-child {
    margin-bottom: 0;
  }
  .widget-area .widget .widget-title {
    font-size: 17px;
  }
  .widget-area .widget_raque_posts_thumb .item .info {
    margin-top: 0;
  }
  .widget-area .widget_raque_posts_thumb .item .info time {
    margin-top: 0;
  }
  .widget-area .widget_raque_posts_thumb .item .info .title {
    font-size: 14px;
  }
  .widget-area .widget_categories ul li {
    font-size: 13px;
  }
  .widget-area .widget_categories ul li::before {
    top: 6px;
  }
  .widget-area .tagcloud a {
    padding: 6px 15px;
    font-size: 13px !important;
  }

  .faq-accordion-tab .tabs {
    margin-bottom: 20px;
  }
  .faq-accordion-tab .tabs li {
    margin-bottom: 20px;
  }
  .faq-accordion-tab .tabs li a {
    padding: 15px;
    font-size: 14px;
  }
  .faq-accordion-tab .tabs li a i {
    font-size: 25px;
  }

  .faq-accordion .accordion .accordion-title {
    padding: 18px 20px 15px 47px;
    font-size: 15px;
  }
  .faq-accordion .accordion .accordion-title i {
    left: 15px;
    top: 16px;
  }
  .faq-accordion .accordion .accordion-content {
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 20px;
  }

  .error-404-area {
    overflow: hidden;
    padding-top: 110px;
    padding-bottom: 110px;
  }
  .error-404-area .notfound .notfound-bg div {
    height: 100px;
  }
  .error-404-area .notfound h1 {
    font-size: 50px;
  }
  .error-404-area .notfound h3 {
    font-size: 18px;
  }

  .woocommerce-topbar {
    text-align: center;
  }
  .woocommerce-topbar .topbar-result-count p {
    font-size: 15px;
  }
  .woocommerce-topbar .topbar-ordering {
    margin-top: 5px;
    margin-bottom: 7px;
  }

  .single-product-box .product-content h3 {
    font-size: 18px;
  }
  .single-product-box .product-content .rating {
    margin-top: 10px;
  }

  .product-details-desc {
    margin-top: 30px;
  }
  .product-details-desc h3 {
    font-size: 19px;
  }
  .product-details-desc .product-add-to-cart .default-btn .icon-arrow {
    top: 14px;
  }

  .products-details-tab .tabs li {
    display: block;
    margin-right: 0;
  }
  .products-details-tab .tabs li a {
    display: block;
  }
  .products-details-tab .tab-content {
    padding: 20px;
  }
  .products-details-tab .tab-content .tabs-item .products-details-tab-content .additional-information li span {
    display: block;
    width: 100%;
    margin-bottom: 3px;
  }
  .products-details-tab .tab-content .tabs-item .products-details-tab-content h3 {
    font-size: 18px;
  }
  .products-details-tab .tab-content .tabs-item .products-details-tab-content .product-review-form .review-title .default-btn {
    position: relative;
    margin-top: 15px;
  }
  .products-details-tab .tab-content .tabs-item .products-details-tab-content .product-review-form .review-comments .review-item {
    margin-top: 30px;
    position: relative;
    padding-right: 0;
    border-top: 1px dashed #eeeeee;
    padding-top: 30px;
  }

  .cart-table table thead tr th {
    padding: 0 20px 15px;
    font-size: 14px;
  }
  .cart-table table thead tr th:nth-child(1) {
    padding-left: 0;
  }
  .cart-table table tbody tr td {
    padding-left: 20px;
    padding-right: 20px;
  }
  .cart-table table tbody tr td:nth-child(1) {
    padding-left: 0;
  }

  .cart-buttons .text-right {
    text-align: center !important;
    margin-top: 25px;
  }

  .cart-totals {
    padding: 25px;
    max-width: 100%;
    margin-top: 40px;
  }
  .cart-totals h3 {
    font-size: 18px;
  }

  .billing-details .title {
    font-size: 18px;
  }

  .order-details {
    margin-top: 30px;
  }
  .order-details .title {
    font-size: 18px;
  }
  .order-details .order-table table thead tr th {
    padding: 12px 20px 10px;
    white-space: nowrap;
  }
  .order-details .order-table table tbody tr td {
    white-space: nowrap;
  }

  .coming-soon-area {
    height: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .coming-soon-content {
    padding: 30px 25px;
  }
  .coming-soon-content h2 {
    margin-top: 25px;
    font-size: 27px;
  }
  .coming-soon-content #timer {
    margin-top: 10px;
  }
  .coming-soon-content #timer div {
    width: 95px;
    height: 100px;
    font-size: 35px;
    margin-top: 20px;
  }
  .coming-soon-content #timer div span {
    font-size: 14px;
  }
  .coming-soon-content form {
    max-width: 100%;
    margin-top: 40px;
  }

  .pagination-area {
    margin-top: 0;
  }

  .contact-info-box h3 {
    font-size: 18px;
  }
  .contact-info-box .icon {
    width: 60px;
    height: 60px;
    line-height: 60px;
    font-size: 30px;
  }

  .contact-form {
    padding: 25px;
    max-width: 100%;
  }

  #contactForm .form-group .form-control {
    font-size: 14px;
  }

  .contact-bg-image {
    display: none;
  }

  .myAccount-profile .profile-content {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 20px;
  }
  .myAccount-profile .profile-content h3 {
    font-size: 18px;
  }

  .myAccount-navigation ul {
    margin-top: -8px;
  }
  .myAccount-navigation ul li {
    margin-top: 8px;
  }
  .myAccount-navigation ul li a {
    padding: 9px 15px 9px 40px;
    font-size: 13px;
  }
  .myAccount-navigation ul li a i {
    font-size: 15px;
    left: 20px;
  }

  .myAccount-content h3 {
    font-size: 18px;
  }
  .myAccount-content .recent-orders-table .table thead th {
    padding: 10px 15px 9px;
    font-size: 14px;
  }
  .myAccount-content .recent-orders-table .table tbody td {
    padding: 10px 15px 9px;
    font-size: 13px;
  }
  .myAccount-content .recent-orders-table .table tbody td .view-button {
    font-size: 13px;
  }
  .myAccount-content .orders-table .table thead th {
    padding: 10px 15px 9px;
    font-size: 14px;
  }
  .myAccount-content .orders-table .table tbody td {
    padding: 10px 15px 9px;
    font-size: 13px;
  }
  .myAccount-content .orders-table .table tbody td .view-button {
    font-size: 13px;
  }
  .myAccount-content .downloads-table .table thead th {
    padding: 10px 15px 9px;
    font-size: 14px;
  }
  .myAccount-content .downloads-table .table tbody td {
    padding: 10px 15px 9px;
    font-size: 13px;
  }
  .myAccount-content .downloads-table .table tbody td .downloads-button {
    font-size: 13px;
  }
  .myAccount-content .edit-account {
    box-shadow: unset;
    padding: 0;
    border-radius: 0;
  }
  .myAccount-content .edit-account .form-group label {
    margin-bottom: 8px;
    font-size: 14px;
  }
  .myAccount-content .edit-account .form-group .form-control {
    font-size: 13.5px;
  }
  .myAccount-content .edit-account legend {
    margin-bottom: 13px;
    font-size: 17px;
  }
  .myAccount-content .edit-account h3 {
    font-size: 18px;
  }
  .myAccount-content .myAccount-addresses .addresses-title h3 {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .myAccount-content .myAccount-addresses .col-lg-6:nth-child(2) .addresses-title {
    margin-top: 30px;
  }

  .footer-area {
    padding-top: 60px;
  }

  .single-footer-widget h3 {
    margin-bottom: 20px;
    font-size: 18px;
  }
  .single-footer-widget .contact-us-link li i {
    font-size: 17px;
    top: 2px;
  }

  .footer-bottom-area {
    margin-top: 30px;
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .go-top {
    right: 10px;
    width: 38px;
    height: 36px;
    line-height: 45px;
    font-size: 25px;
  }
}
@media only screen and (max-width: 576px) {
  .raque-responsive-nav .raque-responsive-menu.mean-container .mean-nav .others-option {
    display: block;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .top-header-contact-info li {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .top-header-contact-info li:last-child {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .raque-responsive-nav .raque-responsive-menu.mean-container .mean-nav .others-option {
    display: block;
  }

  .hero-banner .container-fluid {
    max-width: 540px;
  }

  .about-area .container-fluid {
    max-width: 540px;
  }

  .about-image {
    padding-right: 100px;
    padding-bottom: 50px;
  }
  .about-image img {
    width: auto;
  }
  .about-image img:nth-child(2) {
    position: absolute;
    right: 0;
    margin-top: 0;
  }

  .single-features-box {
    border-right: 1px solid #cccccc;
  }

  .banner-courses-category ul li {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .page-title-area {
    padding-top: 240px;
  }

  .login-content {
    max-width: 548px;
    margin-left: auto;
    margin-right: auto;
  }

  .register-content {
    max-width: 548px;
    margin-left: auto;
    margin-right: auto;
  }

  .blog-details-desc .article-footer {
    text-align: left;
  }
  .blog-details-desc .article-footer .article-tags {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .blog-details-desc .article-footer .article-share {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .blog-details-desc .article-footer .article-share .social {
    text-align: right;
    margin-top: 0;
  }

  .comments-area .comment-author .avatar {
    position: absolute;
    display: inline-block;
    margin-bottom: 0;
    left: -65px;
  }
  .comments-area .comment-body {
    padding-left: 65px;
  }

  .woocommerce-topbar .topbar-result-count {
    margin-bottom: 10px;
  }
  .woocommerce-topbar .topbar-ordering {
    margin-top: 0;
    margin-bottom: 0;
  }

  .cart-buttons .text-right {
    text-align: right !important;
    margin-top: 0;
  }

  .courses-topbar .topbar-result-count {
    margin-bottom: 10px;
  }
  .courses-topbar .topbar-ordering {
    margin-top: 0;
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ptb-100 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .pt-100 {
    padding-top: 80px;
  }

  .pb-100 {
    padding-bottom: 80px;
  }

  .ptb-70 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .pt-70 {
    padding-top: 50px;
  }

  .pb-70 {
    padding-bottom: 50px;
  }

  .section-title {
    margin-bottom: 50px;
  }
  .section-title.text-left {
    text-align: center !important;
  }
  .section-title.text-left h2 {
    max-width: 100%;
  }
  .section-title.text-left .default-btn {
    display: none;
  }

  .top-header {
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .top-header-btn {
    display: none;
  }

  .top-header-contact-info li {
    text-align: left;
  }

  .top-header-logo {
    display: none;
  }

  .top-header-style-two {
    border-bottom: 1px solid #eeeeee;
  }
  .top-header-style-two .top-header-contact-info {
    text-align: center;
  }

  .top-header-style-three {
    text-align: left;
    padding-top: 0;
    padding-bottom: 0;
  }

  .top-header-style-four {
    text-align: left;
    padding-top: 0;
    padding-bottom: 0;
  }

  .raque-responsive-nav .raque-responsive-menu.mean-container .mean-nav {
    margin-top: 60px;
  }
  .raque-responsive-nav .raque-responsive-menu.mean-container .mean-nav ul {
    font-size: 15px;
  }
  .raque-responsive-nav .raque-responsive-menu.mean-container .mean-nav .others-option {
    display: block;
  }
  .raque-responsive-nav .raque-responsive-menu.mean-container .navbar-nav {
    height: 306px;
  }

  .main-banner {
    height: 100%;
    padding-top: 280px;
    padding-bottom: 165px;
  }

  .main-banner-content {
    margin-top: 0;
    max-width: 650px;
  }
  .main-banner-content .sub-title {
    font-size: 13px;
  }
  .main-banner-content h1 {
    font-size: 50px;
    margin-bottom: 15px;
  }
  .main-banner-content p {
    font-size: 15px;
    max-width: 570px;
  }
  .main-banner-content .btn-box {
    margin-top: 30px;
  }

  .banner-section {
    height: 100%;
    padding-top: 280px;
    padding-bottom: 165px;
  }

  .banner-content {
    margin-top: 0;
    max-width: 650px;
  }
  .banner-content .sub-title {
    font-size: 13px;
  }
  .banner-content h1 {
    font-size: 50px;
    margin-bottom: 15px;
  }
  .banner-content p {
    font-size: 15px;
    max-width: 570px;
  }
  .banner-content .btn-box {
    margin-top: 30px;
  }
  .banner-content::before {
    top: -55px;
    max-width: 340px;
    height: 400px;
    left: 15%;
    margin-left: -142px;
  }

  .home-slides.owl-theme .owl-nav [class*=owl-] {
    top: auto;
    transform: unset;
    bottom: 20px;
    z-index: 2;
  }

  .divider {
    bottom: -1px;
  }

  .hero-banner {
    height: auto;
    padding-top: 125px;
    padding-bottom: 100px;
  }
  .hero-banner .container-fluid {
    max-width: 720px;
  }
  .hero-banner.bg-white {
    padding-top: 120px;
    padding-bottom: 0;
  }

  .hero-banner-content {
    max-width: 650px;
    margin-left: 0;
  }
  .hero-banner-content .sub-title {
    font-size: 13px;
  }
  .hero-banner-content h1 {
    font-size: 50px;
    margin-bottom: 15px;
  }
  .hero-banner-content p {
    font-size: 15px;
    max-width: 570px;
  }
  .hero-banner-content .btn-box {
    margin-top: 30px;
  }

  .hero-banner-image {
    padding-left: 0;
    margin-top: 30px;
  }

  .wrapper-content {
    padding-right: 0;
    max-width: 650px;
    margin-bottom: 35px;
  }
  .wrapper-content h1 {
    font-size: 45px;
    margin-bottom: 15px;
  }

  .banner-wrapper {
    padding-top: 280px;
    padding-bottom: 120px;
  }

  .banner-courses-category {
    padding-left: 0;
  }

  .slider_area .tp-caption.NotGeneric-Title, .slider_area .tp-caption .NotGeneric-Title {
    font-size: 45px !important;
  }

  .about-area .container-fluid {
    max-width: 720px;
  }

  .about-image {
    margin-bottom: 30px;
  }

  .about-content {
    padding-left: 0;
    text-align: center;
    max-width: 575px;
    margin-left: auto;
    margin-right: auto;
  }
  .about-content .features-text {
    text-align: left;
  }
  .about-content.left-content {
    max-width: 100%;
    padding-right: 0;
  }

  .about-right-image {
    margin-top: 30px;
  }

  .about-inner-area {
    margin-top: 20px;
  }

  .about-text {
    margin-top: 30px;
  }

  .single-categories-courses-box {
    padding: 20px 15px;
  }
  .single-categories-courses-box h3 {
    font-size: 18px;
  }

  .courses-categories-slides.owl-theme .owl-nav {
    margin-bottom: 30px;
  }
  .courses-categories-slides.owl-theme .owl-nav [class*=owl-] {
    font-size: 22px;
    width: 40px;
    height: 40px;
    line-height: 42px;
    position: relative;
    left: 0 !important;
    top: 0;
    transform: unset;
    opacity: 1;
    visibility: visible;
    margin-left: 5px;
    margin-right: 5px;
  }
  .courses-categories-slides.owl-theme .owl-nav [class*=owl-].owl-next {
    left: auto !important;
    right: 0 !important;
  }

  .single-categories-courses-item {
    padding: 40px 15px;
  }
  .single-categories-courses-item h3 {
    font-size: 18px;
  }
  .single-categories-courses-item .learn-more-btn {
    font-size: 14px;
  }

  .funfacts-inner {
    padding: 30px;
  }

  .single-funfact h3 {
    font-size: 38px;
  }
  .single-funfact p {
    font-size: 15px;
  }

  .single-features-box {
    min-height: 440px;
    border-bottom: 1px solid #cccccc;
  }

  .col-lg-3:nth-child(3) .single-features-box {
    border-bottom: none;
  }
  .col-lg-3:nth-child(4) .single-features-box {
    border-bottom: none;
  }

  .courses-slides.owl-theme .owl-nav [class*=owl-] {
    font-size: 22px;
    width: 40px;
    height: 40px;
    line-height: 42px;
    position: relative;
    left: 0 !important;
    top: 0;
    transform: unset;
    opacity: 1;
    visibility: visible;
    margin-left: 5px;
    margin-right: 5px;
  }
  .courses-slides.owl-theme .owl-nav [class*=owl-].owl-next {
    left: auto !important;
    right: 0 !important;
  }
  .courses-slides.owl-theme .owl-dots {
    display: none !important;
  }

  .shorting-menu {
    text-align: center;
    margin-bottom: 30px;
    margin-top: -10px;
  }
  .shorting-menu .filter {
    margin-bottom: 10px;
  }

  .team-slides.owl-theme .owl-nav {
    margin-bottom: 30px;
  }
  .team-slides.owl-theme .owl-nav [class*=owl-] {
    font-size: 22px;
    width: 40px;
    height: 40px;
    line-height: 42px;
    position: relative;
    left: 0 !important;
    top: 0;
    transform: unset;
    opacity: 1;
    visibility: visible;
    margin-left: 5px;
    margin-right: 5px;
  }
  .team-slides.owl-theme .owl-nav [class*=owl-].owl-next {
    left: auto !important;
    right: 0 !important;
  }

  .offer-area .section-title {
    margin-bottom: 20px;
  }

  .single-offer-box {
    margin-top: 30px;
  }

  .single-work-process::before {
    display: none;
  }

  .become-instructor-partner-content {
    padding: 25px;
  }
  .become-instructor-partner-content h2 {
    margin-bottom: 10px;
    font-size: 30px;
  }

  .students-feedback-form-content {
    text-align: center;
    margin-bottom: 50px;
  }
  .students-feedback-form-content h2 {
    font-size: 37px;
    max-width: 580px;
    margin-left: auto;
    margin-right: auto;
  }

  .students-feedback-form::after {
    display: none;
  }

  .feedback-form-area {
    margin-top: 0;
  }

  .feedback-form-image {
    display: none;
  }

  .feedback-form-inner .feedback-form form {
    padding: 35px;
  }

  .single-events-box .events-box .events-date .date h3 {
    font-size: 22px;
  }

  .courses-details-header .courses-price {
    text-align: left;
    margin-top: 30px;
  }

  .contact-form {
    max-width: 700px;
  }

  .instructor-details-desc .instructor-details-sidebar .instructor-details-meta ul li {
    padding-left: 0;
  }
  .instructor-details-desc .instructor-details-sidebar .instructor-details-meta ul li i {
    display: none;
  }
  .instructor-details-desc .instructor-details .instructor-details-info .row .col-lg-6:nth-child(1) {
    margin-bottom: 25px;
  }

  .feedback-slides.owl-theme .owl-nav [class*=owl-] {
    left: 0 !important;
    top: auto;
    transform: unset;
    bottom: 40px;
    opacity: 1;
    visibility: visible;
  }
  .feedback-slides.owl-theme .owl-nav [class*=owl-].owl-next {
    left: 75px !important;
    right: auto;
  }

  .blog-slides.owl-theme .owl-nav {
    margin-bottom: 30px;
  }
  .blog-slides.owl-theme .owl-nav [class*=owl-] {
    font-size: 22px;
    width: 40px;
    height: 40px;
    line-height: 42px;
    position: relative;
    left: 0 !important;
    top: 0;
    transform: unset;
    opacity: 1;
    visibility: visible;
    margin-left: 5px;
    margin-right: 5px;
  }
  .blog-slides.owl-theme .owl-nav [class*=owl-].owl-next {
    left: auto !important;
    right: 0 !important;
  }

  .pricing-table table thead tr th {
    white-space: nowrap;
  }
  .pricing-table table tbody td {
    white-space: nowrap;
  }

  .login-image {
    background-image: none !important;
  }
  .login-image img {
    display: inline-block;
  }

  .login-content {
    height: 100%;
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .register-image {
    background-image: none !important;
  }
  .register-image img {
    display: inline-block;
  }

  .register-content {
    height: 100%;
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .faq-accordion-tab .tabs {
    margin-bottom: 30px;
  }
  .faq-accordion-tab .tabs li {
    margin-bottom: 20px;
  }

  .coming-soon-content {
    max-width: 720px;
  }

  .widget-area {
    margin-top: 40px;
  }

  .countdown-content {
    text-align: center;
    margin-bottom: 50px;
  }

  .page-title-area {
    padding-top: 300px;
    padding-bottom: 110px;
  }

  .myAccount-profile .profile-content h3 {
    font-size: 19px;
  }
  .myAccount-profile .profile-content p {
    font-size: 14px;
  }
  .myAccount-profile .profile-content .contact-info li {
    font-size: 14px;
  }

  .myAccount-navigation ul {
    margin-top: -8px;
  }
  .myAccount-navigation ul li {
    margin-top: 8px;
  }
  .myAccount-navigation ul li a {
    font-size: 14px;
  }

  .myAccount-content h3 {
    font-size: 20px;
  }
  .myAccount-content .recent-orders-table .table thead th {
    font-size: 15px;
  }
  .myAccount-content .recent-orders-table .table tbody td {
    font-size: 14px;
  }
  .myAccount-content .recent-orders-table .table tbody td .view-button {
    font-size: 13px;
  }
  .myAccount-content .orders-table .table thead th {
    font-size: 15px;
  }
  .myAccount-content .orders-table .table tbody td {
    font-size: 14px;
  }
  .myAccount-content .orders-table .table tbody td .view-button {
    font-size: 13px;
  }
  .myAccount-content .downloads-table .table thead th {
    font-size: 15px;
  }
  .myAccount-content .downloads-table .table tbody td {
    font-size: 14px;
  }
  .myAccount-content .downloads-table .table tbody td .downloads-button {
    font-size: 13px;
  }

  .footer-area {
    padding-top: 80px;
  }

  .footer-bottom-area {
    margin-top: 50px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .courses-categories-slides.owl-theme .owl-nav {
    margin-bottom: 30px;
  }
  .courses-categories-slides.owl-theme .owl-nav [class*=owl-] {
    position: relative;
    left: 0 !important;
    top: 0;
    transform: unset;
    opacity: 1;
    visibility: visible;
    margin-left: 5px;
    margin-right: 5px;
  }
  .courses-categories-slides.owl-theme .owl-nav [class*=owl-].owl-next {
    left: 0 !important;
    right: 0 !important;
  }

  .blog-slides.owl-theme .owl-nav {
    margin-top: 10px;
    margin-bottom: 30px;
  }
  .blog-slides.owl-theme .owl-nav [class*=owl-] {
    position: relative;
    left: 0 !important;
    top: 0;
    transform: unset;
    opacity: 1;
    visibility: visible;
    margin-left: 5px;
    margin-right: 5px;
  }
  .blog-slides.owl-theme .owl-nav [class*=owl-].owl-next {
    left: 0 !important;
    right: 0 !important;
  }

  .team-slides.owl-theme .owl-nav {
    margin-top: 10px;
    margin-bottom: 30px;
  }
  .team-slides.owl-theme .owl-nav [class*=owl-] {
    position: relative;
    left: 0 !important;
    top: 0;
    transform: unset;
    opacity: 1;
    visibility: visible;
    margin-left: 5px;
    margin-right: 5px;
  }
  .team-slides.owl-theme .owl-nav [class*=owl-].owl-next {
    left: 0 !important;
    right: 0 !important;
  }

  .courses-slides.owl-theme .owl-nav {
    margin-top: 10px;
    margin-bottom: 30px;
  }
  .courses-slides.owl-theme .owl-nav [class*=owl-] {
    position: relative;
    left: 0 !important;
    top: 0;
    transform: unset;
    opacity: 1;
    visibility: visible;
    margin-left: 5px;
    margin-right: 5px;
  }
  .courses-slides.owl-theme .owl-nav [class*=owl-].owl-next {
    left: 0 !important;
    right: 0 !important;
  }
  .courses-slides.owl-theme .owl-dots {
    display: none !important;
  }

  .single-boxes-item {
    padding: 25px;
    min-height: 232px;
  }

  .countdown-timer #timer div {
    margin-left: 25px;
    margin-right: 25px;
  }

  .single-features-box {
    padding: 20px;
  }
  .single-features-box .inner-content {
    left: 20px;
    bottom: 20px;
    right: 20px;
  }

  .single-courses-box .courses-content {
    padding: 20px;
  }
  .single-courses-box .courses-content h3 {
    font-size: 18px;
  }
  .single-courses-box .courses-box-footer {
    padding: 20px 20px;
  }

  .become-instructor-partner-content {
    padding: 50px;
  }

  .myAccount-navigation ul {
    margin-top: -8px;
  }
  .myAccount-navigation ul li {
    margin-top: 8px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1275px) {
  .courses-categories-slides.owl-theme .owl-nav [class*=owl-] {
    left: -30px;
  }
  .courses-categories-slides.owl-theme .owl-nav [class*=owl-].owl-next {
    left: auto;
    right: -30px;
  }
  .courses-categories-slides.owl-theme:hover .owl-nav [class*=owl-], .courses-categories-slides.owl-theme:focus .owl-nav [class*=owl-] {
    left: -40px;
  }
  .courses-categories-slides.owl-theme:hover .owl-nav [class*=owl-].owl-next, .courses-categories-slides.owl-theme:focus .owl-nav [class*=owl-].owl-next {
    left: auto;
    right: -40px;
  }

  .courses-slides.owl-theme .owl-nav [class*=owl-] {
    left: -30px;
  }
  .courses-slides.owl-theme .owl-nav [class*=owl-].owl-next {
    left: auto;
    right: -30px;
  }
  .courses-slides.owl-theme:hover .owl-nav [class*=owl-], .courses-slides.owl-theme:focus .owl-nav [class*=owl-] {
    left: -40px;
  }
  .courses-slides.owl-theme:hover .owl-nav [class*=owl-].owl-next, .courses-slides.owl-theme:focus .owl-nav [class*=owl-].owl-next {
    left: auto;
    right: -40px;
  }

  .blog-slides.owl-theme .owl-nav [class*=owl-] {
    left: -30px;
  }
  .blog-slides.owl-theme .owl-nav [class*=owl-].owl-next {
    left: auto;
    right: -30px;
  }
  .blog-slides.owl-theme:hover .owl-nav [class*=owl-], .blog-slides.owl-theme:focus .owl-nav [class*=owl-] {
    left: -40px;
  }
  .blog-slides.owl-theme:hover .owl-nav [class*=owl-].owl-next, .blog-slides.owl-theme:focus .owl-nav [class*=owl-].owl-next {
    left: auto;
    right: -40px;
  }

  .team-slides.owl-theme .owl-nav [class*=owl-] {
    left: -30px;
  }
  .team-slides.owl-theme .owl-nav [class*=owl-].owl-next {
    left: auto;
    right: -30px;
  }
  .team-slides.owl-theme:hover .owl-nav [class*=owl-], .team-slides.owl-theme:focus .owl-nav [class*=owl-] {
    left: -40px;
  }
  .team-slides.owl-theme:hover .owl-nav [class*=owl-].owl-next, .team-slides.owl-theme:focus .owl-nav [class*=owl-].owl-next {
    left: auto;
    right: -40px;
  }
}
@media only screen and (min-width: 768px) {
  .explorer {
    visibility: hidden !important;
  }
}

/*# sourceMappingURL=responsive.css.map */
