@charset "UTF-8";
/*================================================
Default CSS
=================================================*/
body {
  background-color: #ffffff;
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  transition: 0.5s;
  color: #252525;
}
a:hover, a:focus {
  color: #92278f;
  text-decoration: none;
}

input, button, a {
  outline: 0 !important;
}

p {
  margin-bottom: 15px;
  line-height: 1.7;
  color: #62646a;
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
}
p:last-child {
  margin-bottom: 0;
}

.d-table {
  width: 100%;
  height: 100%;
}
.d-table-cell {
  vertical-align: middle;
}

img {
  max-width: 100%;
  height: auto;
}

.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.ptb-70 {
  padding-top: 70px;
  padding-bottom: 70px;
}

.ptb-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-30 {
  padding-top: 30px;
}

.pb-70 {
  padding-bottom: 70px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mt-30 {
  margin-top: 30px;
}

.bg-F7F9FB {
  background-color: #F7F9FB;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  color: #252525;
  font-family: "Poppins", sans-serif;
}
.h1:last-child, .h2:last-child, .h3:last-child, .h4:last-child, .h5:last-child, .h6:last-child, h1:last-child, h2:last-child, h3:last-child, h4:last-child, h5:last-child, h6:last-child {
  margin-bottom: 0;
}

/*default-btn*/
.default-btn {
  transition: 0.5s;
  display: inline-block;
  padding: 13px 25px 12px 55px;
  position: relative;
  background-color: #92278f;
  color: #ffffff;
  border-width: 2px;
  border-style: solid;
  border-color: #92278f;
  border-radius: 1px;
  font-size: 14.5px;
  font-weight: 700;
}
.default-btn .label, .default-btn .icon-arrow {
  backface-visibility: hidden;
  transform: translateZ(0);
  perspective: 1000;
}
.default-btn .label {
  display: inline-block;
  transition: transform 0.5s cubic-bezier(0.86, 0, 0.07, 1);
}
.default-btn .icon-arrow {
  color: #ffffff;
  top: 11px;
  transition: transform 0.5s cubic-bezier(0.86, 0, 0.07, 1), opacity 0.4s cubic-bezier(0.86, 0, 0.07, 1);
  font-size: 25px;
}
.default-btn .icon-arrow.before {
  left: 20px;
  position: absolute;
  transform-origin: left center;
}
.default-btn .icon-arrow.after {
  opacity: 0;
  position: absolute;
  right: 20px;
  transform: translateX(75%) scaleX(0.1);
  transform-origin: right center;
}
.default-btn:hover, .default-btn:focus {
  background-color: #0eb582;
  color: #ffffff;
  border-color: #0eb582;
}
.default-btn:hover .label, .default-btn:focus .label {
  transform: translateX(-32px);
}
.default-btn:hover .icon-arrow.before, .default-btn:focus .icon-arrow.before {
  opacity: 0;
  transform: translateX(-75%) scaleX(0.1);
}
.default-btn:hover .icon-arrow.after, .default-btn:focus .icon-arrow.after {
  opacity: 1;
  transform: translateX(0) scaleX(1);
}

/*section-title*/
.section-title {
  text-align: center;
  position: relative;
  margin-top: -5px;
  margin-bottom: 60px;
}
.section-title .sub-title {
  text-transform: uppercase;
  display: block;
  color: #92278f;
  font-weight: 500;
  margin-bottom: 10px;
}
.section-title h2 {
  margin-bottom: 10px;
  font-size: 37px;
  font-weight: 700;
}
.section-title p {
  max-width: 620px;
  line-height: 1.8;
  margin-left: auto;
  margin-right: auto;
}
.section-title .default-btn {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
.section-title.text-left h2 {
  max-width: 820px;
  margin-bottom: 0;
}
.section-title.text-left p {
  margin-bottom: 0;
  margin-left: 0;
  margin-top: 15px;
  margin-right: 0;
}

/*form-control*/
.form-control {
  height: 48px;
  padding: 0 0 0 12px;
  line-height: initial;
  color: #252525;
  background-color: #ffffff;
  border: 1px solid #e6e9fc;
  border-radius: 3px;
  transition: 0.5s;
  font-size: 15px;
  font-weight: 400;
}
.form-control::placeholder {
  color: #c4c5d0;
}
.form-control:focus {
  box-shadow: unset;
  border-color: #d7d9eb;
}

textarea.form-control {
  height: auto !important;
  padding-top: 12px;
}

/*================================================
Header CSS
=================================================*/
.header-area {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: auto;
  width: 100%;
  z-index: 999;
}
.header-area.p-relative {
  position: relative;
}

.top-header {
  background-color: #92278f;
  padding-top: 15px;
  padding-bottom: 25px;
}

.top-header-contact-info {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.top-header-contact-info li {
  display: inline-block;
  color: #ffffff;
  margin-right: 20px;
  position: relative;
  padding-left: 38px;
  font-size: 13px;
}
.top-header-contact-info li i {
  position: relative;
  padding-right: 5px;
  font-size: 15px;
}
.top-header-contact-info li span {
  display: block;
  margin-bottom: 2px;
}
.top-header-contact-info li a {
  display: inline-block;
  color: #ffffff;
  font-size: 15px;
  font-weight: 700;
}
.top-header-contact-info li a:hover, .top-header-contact-info li a:focus {
  opacity: 0.95;
}
.top-header-contact-info li:last-child {
  margin-right: 0;
}

.top-header-btn {
  text-align: right;
}
.top-header-btn .default-btn {
  background-color: #ffffff;
  color: #92278f;
  border-color: #ffffff;
}
.top-header-btn .default-btn .icon-arrow {
  color: #92278f;
  transition: 0.5s;
}
.top-header-btn .default-btn:hover, .top-header-btn .default-btn:focus {
  background-color: #252525;
  color: #ffffff;
  border-color: #252525;
}
.top-header-btn .default-btn:hover .icon-arrow, .top-header-btn .default-btn:focus .icon-arrow {
  color: #ffffff;
}

.top-header-style-two {
  background-color: #ffffff;
  padding-top: 15px;
  padding-bottom: 15px;
}
.top-header-style-two .top-header-contact-info {
  text-align: right;
}
.top-header-style-two .top-header-contact-info li {
  color: #62646a;
  text-align: left;
}
.top-header-style-two .top-header-contact-info li i {
  color: #92278f;
}
.top-header-style-two .top-header-contact-info li a {
  color: #252525;
}
.top-header-style-two .top-header-contact-info li a:hover, .top-header-style-two .top-header-contact-info li a:focus {
  color: #92278f;
  opacity: 1;
}

.top-header-style-three {
  background-color: #1D1D1D;
  padding-top: 0;
  padding-bottom: 0;
}
.top-header-style-three .top-header-contact-info {
  display: inline-block;
  padding-top: 10px;
  padding-bottom: 10px;
}
.top-header-style-three .top-header-contact-info li {
  padding-left: 0;
  font-size: 14px;
  color: #cccccc;
}
.top-header-style-three .top-header-contact-info li a {
  font-size: 14px;
  color: #cccccc;
  font-weight: 500;
}
.top-header-style-three .top-header-contact-info li a:hover, .top-header-style-three .top-header-contact-info li a:focus {
  color: #92278f;
}

.top-header-login-register {
  text-align: right;
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.top-header-login-register li {
  display: inline-block;
  color: #cccccc;
  margin-right: 20px;
}
.top-header-login-register li a {
  display: inline-block;
  color: #cccccc;
  position: relative;
  padding-left: 20px;
}
.top-header-login-register li a i {
  position: absolute;
  left: 0;
  top: 3px;
}
.top-header-login-register li a:hover, .top-header-login-register li a:focus {
  color: #92278f;
}
.top-header-login-register li:last-child {
  margin-right: 0;
}

.top-header-social {
  display: inline-block;
  margin-left: 20px;
  border-left: 1px solid #4a4646;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
}
.top-header-social span {
  display: inline-block;
  color: #cccccc;
  margin-right: 5px;
}
.top-header-social a {
  display: inline-block;
  color: #cccccc;
  position: relative;
  top: 2px;
  font-size: 17px;
  margin-right: 2px;
}
.top-header-social a:hover, .top-header-social a:focus {
  color: #92278f;
}

.top-header-style-four {
  background-color: #92278f;
  padding-top: 0;
  padding-bottom: 0;
}
.top-header-style-four .top-header-contact-info {
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
}
.top-header-style-four .top-header-contact-info li {
  padding-left: 6px;
  font-size: 14px;
  color: #ffffff;
}
.top-header-style-four .top-header-contact-info li a {
  font-size: 14px;
  color: #ffffff;
  font-weight: 500;
}
.top-header-style-four .top-header-social {
  border-left: 1px solid rgba(255, 255, 255, 0.6);
}
.top-header-style-four .top-header-social span {
  color: #ffffff;
}
.top-header-style-four .top-header-social a {
  color: #ffffff;
}
.top-header-style-four .top-header-login-register li {
  color: #ffffff;
}
.top-header-style-four .top-header-login-register li a {
  color: #ffffff;
}

.raque-responsive-nav {
  display: none;
}

.raque-nav {
  margin-top: -5px;
}
.raque-nav .navbar {
  background-color: #ffffff;
  padding: 0 20px;
  border-radius: 3px;
}
.raque-nav .navbar .navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}
.raque-nav .navbar ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}
.raque-nav .navbar .navbar-nav {
  margin-left: auto;
  margin-right: auto;
}
.raque-nav .navbar .navbar-nav .nav-item {
  position: relative;
  padding: 0;
}
.raque-nav .navbar .navbar-nav .nav-item a {
  color: #252525;
  font-size: 16.5px;
  font-weight: 500;
  padding-left: 0;
  padding-right: 0;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-left: 13px;
  margin-right: 13px;
}
.raque-nav .navbar .navbar-nav .nav-item a:hover, .raque-nav .navbar .navbar-nav .nav-item a:focus, .raque-nav .navbar .navbar-nav .nav-item a.active {
  color: #92278f;
}
.raque-nav .navbar .navbar-nav .nav-item a i {
  font-size: 17px;
  position: relative;
  top: 2px;
  display: inline-block;
  margin-left: -3px;
  margin-right: -3px;
}
.raque-nav .navbar .navbar-nav .nav-item:last-child a {
  margin-right: 0;
}
.raque-nav .navbar .navbar-nav .nav-item:first-child a {
  margin-left: 0;
}
.raque-nav .navbar .navbar-nav .nav-item:hover a, .raque-nav .navbar .navbar-nav .nav-item.active a {
  color: #92278f;
}
.raque-nav .navbar .navbar-nav .nav-item .dropdown-menu {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  position: absolute;
  border: none;
  top: 82px;
  left: 0;
  width: 250px;
  z-index: 99;
  display: block;
  opacity: 0;
  visibility: hidden;
  border-radius: 0;
  transition: all 0.2s ease-in-out;
  border-top: 2px solid #92278f;
  padding: 0;
  transform: scaleX(0);
}
.raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li {
  padding: 0;
}
.raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li a {
  padding: 13px 20px 11px;
  margin: 0;
  position: relative;
  display: block;
  color: #4a6f8a;
  border-bottom: 1px dashed #e5e5e5;
  font-size: 15px;
  font-weight: 500;
}
.raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li a i {
  margin: 0;
  position: absolute;
  top: 50%;
  font-size: 20px;
  transform: translateY(-50%);
  right: 10px;
}
.raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:hover, .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:focus, .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.active {
  color: #92278f;
}
.raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li:last-child a {
  border-bottom: none;
}
.raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
  left: -250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
  transform: scaleY(0);
}
.raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
  color: #4a6f8a;
}
.raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:hover, .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:focus, .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.active {
  color: #92278f;
}
.raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: 250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
  transform: scaleY(0);
}
.raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #4a6f8a;
}
.raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #92278f;
}
.raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: -250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
  transform: scaleY(0);
}
.raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #4a6f8a;
}
.raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #92278f;
}
.raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: 250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
  transform: scaleY(0);
}
.raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #4a6f8a;
}
.raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #92278f;
}
.raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: -250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
  transform: scaleY(0);
}
.raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #4a6f8a;
}
.raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #92278f;
}
.raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: 250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
  transform: scaleY(0);
}
.raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #4a6f8a;
}
.raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #92278f;
}
.raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #ffffff;
  background-color: #92278f;
}
.raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  transform: scaleY(1);
}
.raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #92278f;
}
.raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  transform: scaleY(1);
}
.raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #92278f;
}
.raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  transform: scaleY(1);
}
.raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #92278f;
}
.raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  transform: scaleY(1);
}
.raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li.active a {
  color: #92278f;
}
.raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  transform: scaleY(1);
}
.raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li.active a {
  color: #92278f;
}
.raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  transform: scaleY(1);
}
.raque-nav .navbar .navbar-nav .nav-item:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  transform: scaleX(1);
}
.raque-nav .navbar .others-option .cart-wrapper-btn {
  margin-left: 8px;
  font-size: 25px;
  line-height: 1;
  position: relative;
  top: 5px;
}
.raque-nav .navbar .others-option .cart-wrapper-btn i {
  line-height: 1;
}
.raque-nav .navbar .others-option .cart-wrapper-btn span {
  position: absolute;
  right: -5px;
  top: -5px;
  width: 15px;
  height: 15px;
  background-color: red;
  color: #ffffff;
  line-height: 16.5px;
  text-align: center;
  border-radius: 3px;
  font-weight: 500;
  font-size: 10px;
}
.raque-nav .navbar .others-option .search-box {
  margin-left: 16px;
  cursor: pointer;
  font-size: 25px;
  color: #252525;
  transition: 0.5s;
  line-height: 1;
  position: relative;
  top: 4px;
}
.raque-nav .navbar .others-option .search-box i {
  line-height: 1;
}
.raque-nav .navbar .others-option .search-box:hover {
  color: #92278f;
}

.explorer.header-stickym {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  background: #92278f !important;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
}
.explorer.header-stickym .raque-nav {
  margin-top: 0;
}
.explorer.header-stickym .raque-nav .navbar {
  box-shadow: unset;
  padding: 0;
}
.explorer.header-stickym.is-sticky {
  opacity: 1;
  visibility: visible;
}
.explorer.header-stickym.scrollUp {
  transform: translateY(-85px);
  opacity: 0;
  visibility: hidden;
}

.navbar-area.header-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  background: #ffffff !important;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
}
.navbar-area.header-sticky .raque-nav {
  margin-top: 0;
}
.navbar-area.header-sticky .raque-nav .navbar {
  box-shadow: unset;
  padding: 0;
}
.navbar-area.header-sticky.is-sticky {
  opacity: 1;
  visibility: visible;
}
.navbar-area.header-sticky.scrollUp {
  transform: translateY(-85px);
  opacity: 0;
  visibility: hidden;
}

.navbar-style-two {
  background-color: transparent;
}
.navbar-style-two .raque-nav {
  margin-top: 0;
  background-color: transparent;
  border-bottom-width: 1px;
  border-bottom-color: rgba(255, 255, 255, 0.1);
  border-bottom-style: solid;
}
.navbar-style-two .raque-nav .navbar {
  border-radius: 0;
  padding: 0;
  background-color: transparent;
}
.navbar-style-two .raque-nav .navbar .navbar-nav {
  margin-left: 0;
  margin-right: 0;
}
.navbar-style-two .raque-nav .navbar .navbar-nav .nav-item a {
  color: #ffffff;
}
.navbar-style-two .raque-nav .navbar .navbar-nav .nav-item a:hover, .navbar-style-two .raque-nav .navbar .navbar-nav .nav-item a:focus, .navbar-style-two .raque-nav .navbar .navbar-nav .nav-item a.active {
  color: #92278f;
}
.navbar-style-two .raque-nav .navbar .navbar-nav .nav-item:hover a, .navbar-style-two .raque-nav .navbar .navbar-nav .nav-item.active a {
  color: #92278f;
}
.navbar-style-two .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu {
  left: 0;
}
.navbar-style-two .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li a {
  color: #4a6f8a;
}
.navbar-style-two .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:hover, .navbar-style-two .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:focus, .navbar-style-two .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.active {
  color: #92278f;
}
.navbar-style-two .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
  left: 250px;
}
.navbar-style-two .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
  color: #4a6f8a;
}
.navbar-style-two .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:hover, .navbar-style-two .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:focus, .navbar-style-two .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.active {
  color: #92278f;
}
.navbar-style-two .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: -250px;
}
.navbar-style-two .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #4a6f8a;
}
.navbar-style-two .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .navbar-style-two .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .navbar-style-two .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #92278f;
}
.navbar-style-two .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: 250px;
}
.navbar-style-two .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #4a6f8a;
}
.navbar-style-two .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .navbar-style-two .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .navbar-style-two .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #92278f;
}
.navbar-style-two .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: -250px;
}
.navbar-style-two .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #4a6f8a;
}
.navbar-style-two .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .navbar-style-two .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .navbar-style-two .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #92278f;
}
.navbar-style-two .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: 250px;
}
.navbar-style-two .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #4a6f8a;
}
.navbar-style-two .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .navbar-style-two .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .navbar-style-two .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #92278f;
}
.navbar-style-two .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: -250px;
}
.navbar-style-two .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #4a6f8a;
}
.navbar-style-two .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .navbar-style-two .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .navbar-style-two .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #92278f;
}
.navbar-style-two .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #ffffff;
  background-color: #92278f;
}
.navbar-style-two .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #92278f;
}
.navbar-style-two .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #92278f;
}
.navbar-style-two .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #92278f;
}
.navbar-style-two .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li.active a {
  color: #92278f;
}
.navbar-style-two .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li.active a {
  color: #92278f;
}
.navbar-style-two .raque-nav .navbar .others-option {
  margin-left: auto;
}
.navbar-style-two .raque-nav .navbar .others-option .cart-wrapper-btn {
  color: #ffffff;
}
.navbar-style-two .raque-nav .navbar .others-option .cart-wrapper-btn:hover, .navbar-style-two .raque-nav .navbar .others-option .cart-wrapper-btn:focus {
  color: #92278f;
}
.navbar-style-two .raque-nav .navbar .others-option .search-box {
  color: #ffffff;
}
.navbar-style-two .raque-nav .navbar .others-option .search-box:hover {
  color: #92278f;
}
.navbar-style-two.is-sticky .raque-nav {
  border: none;
}
.navbar-style-two.is-sticky .raque-nav .navbar .navbar-nav .nav-item a {
  color: #252525;
}
.navbar-style-two.is-sticky .raque-nav .navbar .navbar-nav .nav-item a:hover, .navbar-style-two.is-sticky .raque-nav .navbar .navbar-nav .nav-item a:focus, .navbar-style-two.is-sticky .raque-nav .navbar .navbar-nav .nav-item a.active {
  color: #92278f;
}
.navbar-style-two.is-sticky .raque-nav .navbar .navbar-nav .nav-item:hover a, .navbar-style-two.is-sticky .raque-nav .navbar .navbar-nav .nav-item.active a {
  color: #92278f;
}
.navbar-style-two.is-sticky .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li a {
  color: #4a6f8a;
  border-bottom: 1px dashed #e5e5e5;
}
.navbar-style-two.is-sticky .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:hover, .navbar-style-two.is-sticky .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:focus, .navbar-style-two.is-sticky .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.active {
  color: #92278f;
}
.navbar-style-two.is-sticky .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
  color: #4a6f8a;
}
.navbar-style-two.is-sticky .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:hover, .navbar-style-two.is-sticky .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:focus, .navbar-style-two.is-sticky .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.active {
  color: #92278f;
}
.navbar-style-two.is-sticky .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #4a6f8a;
}
.navbar-style-two.is-sticky .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .navbar-style-two.is-sticky .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .navbar-style-two.is-sticky .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #92278f;
}
.navbar-style-two.is-sticky .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #4a6f8a;
}
.navbar-style-two.is-sticky .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .navbar-style-two.is-sticky .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .navbar-style-two.is-sticky .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #92278f;
}
.navbar-style-two.is-sticky .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #4a6f8a;
}
.navbar-style-two.is-sticky .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .navbar-style-two.is-sticky .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .navbar-style-two.is-sticky .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #92278f;
}
.navbar-style-two.is-sticky .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #4a6f8a;
}
.navbar-style-two.is-sticky .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .navbar-style-two.is-sticky .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .navbar-style-two.is-sticky .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #92278f;
}
.navbar-style-two.is-sticky .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #4a6f8a;
}
.navbar-style-two.is-sticky .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .navbar-style-two.is-sticky .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .navbar-style-two.is-sticky .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #92278f;
}
.navbar-style-two.is-sticky .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #ffffff;
  background-color: #92278f;
}
.navbar-style-two.is-sticky .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #92278f;
}
.navbar-style-two.is-sticky .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #92278f;
}
.navbar-style-two.is-sticky .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #92278f;
}
.navbar-style-two.is-sticky .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li.active a {
  color: #92278f;
}
.navbar-style-two.is-sticky .raque-nav .navbar .navbar-nav .nav-item .dropdown-menu li.active a {
  color: #92278f;
}
.navbar-style-two.is-sticky .raque-nav .navbar .others-option .cart-wrapper-btn {
  color: #252525;
}
.navbar-style-two.is-sticky .raque-nav .navbar .others-option .cart-wrapper-btn:hover, .navbar-style-two.is-sticky .raque-nav .navbar .others-option .cart-wrapper-btn:focus {
  color: #92278f;
}
.navbar-style-two.is-sticky .raque-nav .navbar .others-option .search-box {
  color: #252525;
}
.navbar-style-two.is-sticky .raque-nav .navbar .others-option .search-box:hover {
  color: #92278f;
}

.navbar-style-three .raque-nav {
  margin-top: 0;
  background-color: #ffffff;
}
.navbar-style-three .raque-nav .navbar {
  border-radius: 0;
  padding: 0;
}
.navbar-style-three .raque-nav .navbar .navbar-nav {
  margin-left: auto;
  margin-right: 0;
}
.navbar-style-three .raque-nav .navbar .others-option {
  margin-left: 25px;
}
.navbar-style-three.header-sticky .raque-nav .navbar .navbar-nav {
  margin-left: auto;
  margin-right: 0;
}
.navbar-style-three.header-sticky .raque-nav .navbar .others-option {
  margin-left: 25px;
}

.navbar-style-four .raque-nav {
  margin-top: 0;
  background-color: #ffffff;
}
.navbar-style-four .raque-nav .navbar {
  border-radius: 0;
  padding: 0;
}

@media only screen and (max-width: 991px) {
  .raque-responsive-nav {
    display: block;
  }
  .raque-responsive-nav .raque-responsive-menu {
    position: relative;
  }
  .raque-responsive-nav .raque-responsive-menu.mean-container .mean-nav {
    margin-top: 53px;
  }
  .raque-responsive-nav .raque-responsive-menu.mean-container .mean-nav ul {
    font-size: 14px;
  }
  .raque-responsive-nav .raque-responsive-menu.mean-container .mean-nav ul li a.active {
    color: #92278f;
  }
  .raque-responsive-nav .raque-responsive-menu.mean-container .mean-nav ul li li a {
    font-size: 14px;
  }
  .raque-responsive-nav .raque-responsive-menu.mean-container .mean-nav .others-option {
    display: none;
    position: absolute;
    right: 50px;
    top: 4px;
  }
  .raque-responsive-nav .raque-responsive-menu.mean-container .mean-nav .others-option .cart-wrapper-btn {
    margin-left: 8px;
    font-size: 25px;
    line-height: 1;
    position: relative;
    top: 5px;
  }
  .raque-responsive-nav .raque-responsive-menu.mean-container .mean-nav .others-option .cart-wrapper-btn i {
    line-height: 1;
  }
  .raque-responsive-nav .raque-responsive-menu.mean-container .mean-nav .others-option .cart-wrapper-btn span {
    position: absolute;
    right: -5px;
    top: -5px;
    width: 15px;
    height: 15px;
    background-color: red;
    color: #ffffff;
    line-height: 16.5px;
    text-align: center;
    border-radius: 3px;
    font-weight: 500;
    font-size: 10px;
  }
  .raque-responsive-nav .raque-responsive-menu.mean-container .mean-nav .others-option .search-box {
    margin-left: 16px;
    cursor: pointer;
    font-size: 25px;
    color: #252525;
    transition: 0.5s;
    line-height: 1;
    position: relative;
    top: 4px;
  }
  .raque-responsive-nav .raque-responsive-menu.mean-container .mean-nav .others-option .search-box i {
    line-height: 1;
  }
  .raque-responsive-nav .raque-responsive-menu.mean-container .mean-nav .others-option .search-box:hover {
    color: #92278f;
  }
  .raque-responsive-nav .raque-responsive-menu.mean-container .navbar-nav {
    overflow-y: scroll;
    height: 294px;
    box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
  }
  .raque-responsive-nav .mean-container a.meanmenu-reveal {
    color: #252525;
  }
  .raque-responsive-nav .mean-container a.meanmenu-reveal span {
    background: #252525;
  }
  .raque-responsive-nav .logo {
    position: relative;
    width: 50%;
    z-index: 999;
  }

  .navbar-area {
    background-color: #ffffff;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .navbar-area.header-sticky.is-sticky {
    display: none !important;
  }
  .navbar-area.navbar-style-two .raque-responsive-nav .raque-responsive-menu.mean-container .navbar-nav {
    height: 357px;
  }

  .raque-nav {
    display: none;
  }
}
.error-404-area {
  z-index: 1;
  background: #f8f8f8;
  position: relative;
  padding-top: 145px;
  padding-bottom: 145px;
}
.error-404-area .notfound {
  position: relative;
  max-width: 620px;
  z-index: 1;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.error-404-area .notfound .notfound-bg {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}
.error-404-area .notfound .notfound-bg div {
  width: 100%;
  background: #ffffff;
  border-radius: 90px;
  height: 125px;
}
.error-404-area .notfound .notfound-bg div:nth-child(1) {
  box-shadow: 5px 5px 0 0 #f3f3f3;
}
.error-404-area .notfound .notfound-bg div:nth-child(2) {
  transform: scale(1.3);
  box-shadow: 5px 5px 0 0 #f3f3f3;
  position: relative;
  z-index: 10;
}
.error-404-area .notfound .notfound-bg div:nth-child(3) {
  box-shadow: 5px 5px 0 0 #f3f3f3;
  position: relative;
  z-index: 90;
}
.error-404-area .notfound h1 {
  background: url(/img/oops-bg.jpg) no-repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: cover;
  background-position: center;
  text-transform: uppercase;
  font-size: 100px;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: 10px;
}
.error-404-area .notfound h3 {
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 22px;
}
.error-404-area .notfound p {
  color: #6084a4;
  margin-bottom: 20px;
  line-height: 1.7;
}

/*================================================
Footer CSS
=================================================*/
.footer-area {
  background-color: #12141b;
  padding-top: 100px;
}

.single-footer-widget h3 {
  margin-bottom: 25px;
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
}
.single-footer-widget .contact-us-link {
  margin-bottom: 0;
  list-style-type: none;
  padding-left: 0;
  padding-right: 10px;
}
.single-footer-widget .contact-us-link li {
  margin-bottom: 12px;
  color: #cccccc;
  position: relative;
  padding-left: 25px;
}
.single-footer-widget .contact-us-link li i {
  color: #ffffff;
  font-size: 18px;
  position: absolute;
  left: 0;
  top: 1px;
}
.single-footer-widget .contact-us-link li a {
  display: inline-block;
  color: #cccccc;
}
.single-footer-widget .contact-us-link li a:hover, .single-footer-widget .contact-us-link li a:focus {
  color: #92278f;
}
.single-footer-widget .contact-us-link li:last-child {
  margin-bottom: 0;
}
.single-footer-widget .support-link {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}
.single-footer-widget .support-link li {
  margin-bottom: 11px;
}
.single-footer-widget .support-link li:last-child {
  margin-bottom: 0;
}
.single-footer-widget .support-link li a {
  display: inline-block;
  color: #cccccc;
}
.single-footer-widget .support-link li a:hover, .single-footer-widget .support-link li a:focus {
  color: #92278f;
  padding-left: 7px;
}
.single-footer-widget .useful-link {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}
.single-footer-widget .useful-link li {
  margin-bottom: 11px;
}
.single-footer-widget .useful-link li:last-child {
  margin-bottom: 0;
}
.single-footer-widget .useful-link li a {
  display: inline-block;
  color: #cccccc;
}
.single-footer-widget .useful-link li a:hover, .single-footer-widget .useful-link li a:focus {
  color: #92278f;
  padding-left: 7px;
}
.single-footer-widget .social-link {
  padding-left: 0;
  list-style-type: none;
  margin-top: 25px;
  margin-bottom: 0;
}
.single-footer-widget .social-link li {
  display: inline-block;
  margin-right: 2px;
}
.single-footer-widget .social-link li a {
  width: 30px;
  height: 30px;
  text-align: center;
  color: #92278f;
  background-color: #ffffff;
  font-size: 18px;
  position: relative;
  border-radius: 2px;
}
.single-footer-widget .social-link li a i {
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  transform: translateY(-49%);
}
.single-footer-widget .social-link li a:hover, .single-footer-widget .social-link li a:focus {
  background-color: #92278f;
  color: #ffffff;
}
.single-footer-widget .newsletter-box p {
  color: #cccccc;
  font-family: "Poppins", sans-serif;
}
.single-footer-widget .newsletter-box form {
  position: relative;
}
.single-footer-widget .newsletter-box form label {
  display: block;
  margin-bottom: 10px;
  color: #ffffff;
  font-size: 15px;
}
.single-footer-widget .newsletter-box form .input-newsletter {
  width: 100%;
  background-color: transparent;
  border: 1px solid #848282;
  border-radius: 5px;
  height: 43px;
  padding-left: 10px;
  color: #ffffff;
  font-size: 14px;
  transition: 0.5s;
}
.single-footer-widget .newsletter-box form .input-newsletter::placeholder {
  color: #cccccc;
}
.single-footer-widget .newsletter-box form .input-newsletter:focus {
  padding-left: 12px;
  border-color: #92278f;
}
.single-footer-widget .newsletter-box form button {
  display: block;
  margin-top: 0.9rem;
  width: 100%;
  border-radius: 0.25rem;
  background-color: #92278f;
  color: #ffffff;
  border: none;
  padding: 12px 25px;
  transition: 0.5s;
  font-weight: 500;
  font-size: 15px;
}
.single-footer-widget .newsletter-box form button:hover, .single-footer-widget .newsletter-box form button:focus {
  background-color: #0eb582;
  color: #ffffff;
}
.single-footer-widget .newsletter-box form #validator-newsletter {
  position: absolute;
  left: 0;
  bottom: -35px;
  color: #ffffff;
}

.footer-bottom-area {
  margin-top: 70px;
  text-align: center;
  background-color: #090a0e;
  padding-top: 30px;
  padding-bottom: 30px;
}
.footer-bottom-area .logo {
  margin-bottom: 18px;
}
.footer-bottom-area p {
  color: #cccccc;
  font-size: 14px;
}
.footer-bottom-area p i {
  position: relative;
  top: 1px;
}
.footer-bottom-area p a {
  display: inline-block;
  font-weight: 600;
  color: #ffffff;
}
.footer-bottom-area p a:hover, .footer-bottom-area p a:focus {
  color: #92278f;
  text-decoration: underline;
}

/*================================================
Go Top CSS
=================================================*/
.go-top {
  position: fixed;
  cursor: pointer;
  bottom: -42px;
  right: 15px;
  color: #ffffff;
  background-color: #92278f;
  z-index: 4;
  width: 42px;
  text-align: center;
  height: 42px;
  line-height: 42px;
  opacity: 0;
  visibility: hidden;
  font-size: 28px;
  transition: 0.5s;
  border-radius: 5px 5px 0 0;
}
.go-top i {
  position: absolute;
  left: 0;
  right: 0;
  top: 55%;
  transform: translateY(-55%);
  transition: 0.3s;
}
.go-top.active {
  bottom: 0;
  opacity: 1;
  visibility: visible;
}
.go-top:hover i {
  margin-top: -3px;
}

.page-title-area {
  position: relative;
  z-index: 1;
  background-color: #92278f;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 330px;
  padding-bottom: 120px;
}
.page-title-area.page-title-style-two {
  padding-top: 280px;
  padding-bottom: 120px;
}
.page-title-area.page-title-style-three {
  padding-top: 180px;
  padding-bottom: 140px;
}
.page-title-area.item-bg1 {
  background-image: url(/img/page-title/1.jpg);
}
.page-title-area.item-bg2 {
  background-image: url(/img/page-title/2.jpg);
}
.page-title-area.item-bg3 {
  background-image: url(/img/page-title/3.jpg);
}
.page-title-area.item-bg4 {
  background-image: url(/img/page-title/4.jpg);
}

.page-title-content ul {
  padding-left: 0;
  margin-bottom: 12px;
  list-style-type: none;
}
.page-title-content ul li {
  display: inline-block;
  margin-right: 25px;
  position: relative;
  color: #ffffff;
}
.page-title-content ul li a {
  display: inline-block;
  color: #ffffff;
  opacity: 0.85;
}
.page-title-content ul li a:hover, .page-title-content ul li a:focus {
  color: #92278f;
  opacity: 1;
}
.page-title-content ul li::before {
  content: "";
  color: #ffffff;
  position: absolute;
  right: -25px;
  top: -4px;
  opacity: 0.85;
  font-family: "boxicons" !important;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  font-size: 20px;
}
.page-title-content ul li:last-child {
  margin-right: 0;
}
.page-title-content ul li:last-child::before {
  display: none;
}
.page-title-content h2 {
  color: #ffffff;
  margin-bottom: 0;
  font-size: 42px;
  font-weight: 700;
}

#particles-js-circle-bubble {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}

#particles-js-circle-bubble-2 {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}

#particles-js-circle-bubble-4 {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}

#particles-js-circle-bubble-3 {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}

.pagination-area {
  position: relative;
  z-index: 2;
  margin-top: 20px;
  margin-bottom: -12px;
}
.pagination-area .page-numbers {
  color: #252525;
  display: inline-block;
  font-weight: 500;
  font-size: 19px;
  margin-left: 4px;
  margin-right: 4px;
}
.pagination-area .page-numbers:hover, .pagination-area .page-numbers:focus, .pagination-area .page-numbers.current {
  color: #92278f;
}
.pagination-area .page-numbers.prev, .pagination-area .page-numbers.next {
  font-size: 28px;
  position: relative;
  top: 5px;
  margin-left: -3px;
  margin-right: -3px;
}

.courses-review-comments {
  margin-top: 40px;
}
.courses-review-comments h3 {
  border-bottom: 1px solid #f3f3f3;
  padding-bottom: 20px;
  margin-top: 0;
  margin-bottom: 0;
}
.courses-review-comments .user-review {
  border-bottom: 1px solid #f3f3f3;
  padding: 20px 0 20px 110px;
  position: relative;
}
.courses-review-comments .user-review img {
  position: absolute;
  left: 0;
  top: 20px;
  width: 90px;
  height: 90px;
  border-radius: 5px;
}
.courses-review-comments .user-review .sub-comment {
  margin-bottom: 10px;
  font-weight: 600;
}
.courses-review-comments .user-review .review-rating {
  display: block;
  margin-bottom: 10px;
}
.courses-review-comments .user-review .review-rating .review-stars {
  display: inline-block;
}
.courses-review-comments .user-review .review-rating .review-stars i {
  color: #f2b827;
  font-size: 18px;
  display: inline-block;
  margin-right: -3px;
}
.courses-review-comments .user-review .review-rating span {
  color: #252525;
  position: relative;
  top: -2px;
  font-weight: 500;
  margin-left: 5px;
}

.video-btn {
  display: inline-block;
  width: 80px;
  height: 80px;
  font-size: 60px;
  text-align: center;
  line-height: 90px;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  transform: translateY(-50%) translateX(-50%);
  background-color: #92278f;
  border-radius: 50%;
  color: #ffffff;
}
.video-btn::after, .video-btn::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  bottom: 0;
  left: 0;
  transition: 0.5s;
  border-radius: 50%;
  border: 1px solid #ffffff;
}
.video-btn::before {
  animation: ripple 2s linear infinite;
}
.video-btn::after {
  animation: ripple 2s linear 1s infinite;
}
.video-btn:hover {
  color: #92278f;
  background-color: #ffffff;
}
.video-btn:hover::before, .video-btn:hover::after {
  border-color: #92278f;
}

.socialsharing {
  text-align: center;
}
.socialsharing a {
  display: inline-block;
  padding: 0.7em;
  line-height: 0;
  margin-bottom: 2em;
}
.socialsharing path {
  fill: white;
}
.socialsharing svg {
  width: 24px;
  height: 24px;
}

.students-feedback-form-content h2 {
  color: #ffffff;
  margin-bottom: 0;
  line-height: 1.3;
  font-size: 50px;
  font-weight: 700;
}

.students-feedback-form {
  position: relative;
  z-index: 1;
  text-align: center;
  background-color: #ffffff;
  color: #ffffff;
}
.students-feedback-form h3 {
  background-color: #92278f;
  margin-bottom: 0;
  color: #ffffff;
  padding: 25px 20px;
  font-size: 25px;
  font-weight: 600;
}
.students-feedback-form form {
  color: #252525;
  padding: 30px;
}
.students-feedback-form form .form-group {
  text-align: left;
  position: relative;
  margin-bottom: 30px;
}
.students-feedback-form form .form-group .form-control {
  border-radius: 0;
  border: none;
  border-bottom: 1.3px solid #eeeeee;
  padding: 0 0 0 30px;
  color: #252525;
  height: 45px;
  box-shadow: unset !important;
  transition: 0.5s;
  font-size: 15px;
  font-weight: 400;
}
.students-feedback-form form .form-group .form-control::placeholder {
  transition: 0.2s;
}
.students-feedback-form form .form-group .form-control:focus {
  padding-left: 0;
}
.students-feedback-form form .form-group .form-control:focus::placeholder {
  color: transparent;
}
.students-feedback-form form .form-group .form-control:focus + .label-title::before {
  width: 100%;
}
.students-feedback-form form .form-group .form-control:focus + .label-title {
  color: #A1A1A1;
}
.students-feedback-form form .form-group .form-control:focus + .label-title i {
  top: -15px;
}
.students-feedback-form form .form-group .label-title {
  margin-bottom: 0;
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  color: #252525;
}
.students-feedback-form form .form-group .label-title::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  transition: 0.5s;
  background: #92278f;
}
.students-feedback-form form .form-group .label-title i {
  position: absolute;
  left: 0;
  transition: 0.5s;
  top: 10px;
  font-size: 20px;
}
.students-feedback-form form .form-group textarea.form-control {
  height: auto !important;
  padding-top: 10px;
}
.students-feedback-form::after {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: 20px;
  margin-top: -20px;
  border-right-color: inherit;
  z-index: -1;
}

/*# sourceMappingURL=base.css.map */
